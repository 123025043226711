import React from "react";

const AddCompany = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#fff",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="38098"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M520.3 532.2V91.3c0-13.9-7.1-26.1-17.9-33.2-6.4-4.2-14.1-6.7-22.3-6.7-5.3 0-10.6 1-15.6 3.1L127.9 199.2c-14.4 6.3-24.2 20.5-24.2 36.9v610.7H61.5c-7.7 0.1-13.9 6.4-14 14.1v45.9c0 7.7 6.3 14 14 14.1h477.1c-51.4-52.9-83.1-125.1-83.1-204.7 0-69.7 24.3-133.7 64.8-184zM280.1 798.3c-0.1 7.7-6.4 14-14.1 14.1h-44.7c-7.7 0-14-6.3-14-14v-44.8c0-7.7 6.3-14 14-14.1h44.8c7.7 0 14 6.3 14 14v44.8z m0-112.8c-0.1 7.7-6.4 14-14.1 14.1h-44.7c-7.7 0-14-6.3-14-14v-44.8c0-7.7 6.3-14 14-14.1h44.8c7.7 0.1 13.9 6.4 14 14.1v44.7z m0-112.7c-0.1 7.7-6.4 14-14.1 14.1h-44.7c-7.7-0.1-13.9-6.4-14-14.1v-44.7c0-7.7 6.3-14 14-14.1h44.8c7.7 0 14 6.3 14 14v44.8z m0-112.7c-0.1 7.7-6.4 14-14.1 14.1h-44.7c-7.7 0-14-6.3-14-14v-44.8c0-7.7 6.3-14 14-14.1h44.8c7.7 0 14 6.3 14 14v44.8z m0-112.8c-0.1 7.7-6.4 14-14.1 14.1h-44.7c-7.7 0-14-6.3-14-14v-44.8c0-7.7 6.3-14 14-14.1h44.8c7.7 0.1 13.9 6.4 14 14.1v44.7z m135.7 451c0 7.8-6.3 14.1-14 14.1H357c-7.7 0-14-6.3-14-14v-44.8c0-7.7 6.3-14 14-14.1h44.8c7.7 0 14 6.3 14 14v44.8z m0-112.8c0 7.8-6.3 14.1-14 14.1H357c-7.7 0-14-6.3-14-14v-44.8c0-7.7 6.3-14 14-14.1h44.8c7.7 0.1 13.9 6.4 14 14.1v44.7z m0-112.7c0 7.8-6.3 14.1-14 14.1H357c-7.7-0.1-13.9-6.4-14-14.1v-44.7c0-7.7 6.3-14 14-14.1h44.8c7.7 0 14 6.3 14 14v44.8z m0-112.7c0 7.8-6.3 14.1-14 14.1H357c-7.7 0-14-6.3-14-14v-44.8c0-7.7 6.3-14 14-14.1h44.8c7.7 0 14 6.3 14 14v44.8z m0-112.8c0 7.8-6.3 14.1-14 14.1H357c-7.7 0-14-6.3-14-14v-44.8c0-7.7 6.3-14 14-14.1h44.8c7.7 0.1 13.9 6.4 14 14.1v44.7z m0-112.7c0 7.8-6.3 14.1-14 14.1H357c-7.7 0-14-6.3-14-14V190c0-7.7 6.3-14 14-14.1h44.8c7.7 0 14 6.3 14 14v44.7zM675.2 415.4c0-7.7 6.3-14 14-14.1H734c7.7 0 14 6.3 14 14v7.1h1.2c39.4 0 77 7.8 111.4 21.9V320.1c0-14.5-7.9-27.1-19.5-33.9l-215.8-126c-5.8-3.5-12.7-5.5-20.1-5.5-21.8 0-39.5 17.7-39.5 39.5v292.7c31.8-25.5 69-44.5 109.6-55.1v-16.4z m0-112.7c0-7.7 6.3-14 14-14.1H734c7.7 0.1 13.9 6.4 14 14.1v44.7h0.1c0 7.7-6.3 14-14 14.1h-44.8c-7.7 0-14-6.3-14-14v-44.8z"
      fill={color}
      p-id="38099"
    ></path>
    <path
      d="M914.5 551.2c-90.9-90.9-238.3-90.9-329.3 0-90.9 90.9-90.9 238.3 0 329.3 90.9 90.9 238.3 90.9 329.3 0 90.9-91 90.9-238.4 0-329.3z m-33.7 184.6c0 5-4.1 9.1-9.1 9.1h-92.8v92.8c0 5-4.1 9.1-9.1 9.1h-40c-5 0-9.1-4.1-9.1-9.1v-92.8H628c-5 0-9.1-4.1-9.1-9.1v-40c0-5 4.1-9.1 9.1-9.1h92.8v-92.8c0-5 4.1-9.1 9.1-9.1h40c5 0 9.1 4.1 9.1 9.1v92.8h92.8c5 0 9.1 4.1 9.1 9.1l-0.1 40z"
      p-id="38100"
      fill={color}
    ></path>
  </svg>
);


export default AddCompany;