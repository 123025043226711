import React, { Component } from "react";
import "../style/user.scss";
// import Pagination from "@material-ui/lab/Pagination";
import AddUserIcon from "../icon/AddUserIcon";
import DeleteIcon from "../icon/DeleteIcon";
import EditIcon from "../icon/EditIcon";
import * as GetUserListAPI from "../apis/GetUserListAPI";
import Loading from "./common/Loading";
import NoContent from "../icon/NoContent";
import * as DeleteUserAPI from "../apis/DeleteUserAPI";
import { Pagination } from "antd";
import { BASE_URL } from "../constants/ApiEndpointConstants";
import * as GetInviteCodeAPI from "../apis/GetInviteCodeAPI";
import moment from "moment";
import AlertBox from "./common/AlertBox";
import cookie from "react-cookies";

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      page: 1,
      per_page: 10,
      count: 0,
      userList: [],
      inviteCode: "",
      deleteId: "",
      usertype: null,
    };
    this.toggleShow = this.toggleShow.bind(this);
    this.getUserList = this.getUserList.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.routeAddUser = this.routeAddUser.bind(this);
    this.handlePreviewAlert = this.handlePreviewAlert.bind(this);
    this.confirmHandler = this.confirmHandler.bind(this);
  }

  alertRef = React.createRef();

  componentDidMount() {
    let param = cookie.load("token");
    if (param) {
      console.log(param);
      this.setState({ usertype: param.user.roleType });
    }
    this.getUserList(this.state.page, this.state.per_page);
    GetInviteCodeAPI.getInviteCode().then((res) => {
      var data = res.data.data;
      this.setState({ inviteCode: data });
    });
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  getUserList(page, per_page) {
    this.toggleShow();
    GetUserListAPI.getUserList(page, per_page)
      .then((res) => {
        this.toggleShow();
        var data = res.data.data;
        if (data) {
          var total = data.total;
          console.log(data.list);
          let count = Math.ceil(total / per_page);
          this.setState({
            userList: data.list,
            count,
          });
        }
      })
      .catch((err) => {
        this.toggleShow();
      });
  }

  handlePageChange(e, page, pageSize) {
    // 确保 page 和 pageSize 是数字
    const currentPage = parseInt(page, 10);
    const currentPerPage = parseInt(pageSize, 10);

    if (isNaN(currentPage) || isNaN(currentPerPage)) {
      console.error("Invalid page or pageSize");
      return;
    }
    this.setState({ page: currentPage, per_page: currentPerPage }, () => {
      this.getUserList(currentPage, currentPerPage);
    });
  }

  routeAddUser(id) {
    const { history } = this.props;
    if (id) {
      history.push(`/AddUser/${id}`);
      return;
    }
    history.push(`/AddUser`);
  }

  handlePreviewAlert(id) {
    this.handleToggle();
    this.setState({ deleteId: id });
  }

  handleToggle() {
    if (this.alertRef) {
      this.alertRef.current.toggleAlertShow();
    }
  }

  confirmHandler() {
    this.toggleShow();
    DeleteUserAPI.deleteUser(this.state.deleteId)
      .then((res) => {
        this.toggleShow();
        var code = res.data.code;
        var msg = res.data.msg;
        if (code === 1) {
          window.dispatchEvent(
            new CustomEvent("showAlert", {
              detail: "Delete User Successfully",
            })
          );
          this.getUserList(this.state.page, this.state.per_page);
          return;
        }
        window.dispatchEvent(
          new CustomEvent("showAlert", {
            detail: msg,
          })
        );
      })
      .catch((err) => {
        this.toggleShow();
      });
  }

  render() {
    const { count, page, userList, inviteCode, usertype } = this.state;
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <AlertBox
          ref={this.alertRef}
          {...this.props}
          confirmHandler={this.confirmHandler}
          toggleAlertShow={this.handleToggle}
        />
        <div className="user-toolbar">
          <div className="left">
            INVITE CODE : <span>{inviteCode}</span>
          </div>
          <div className="logo" onClick={() => this.routeAddUser("")}>
            <AddUserIcon size={24} color="#fff" className="icon" />
          </div>
        </div>
        <div className="user-page">
          <div className="head">
            <div className="left">
              <div className="name">ACCOUNT</div>
              <div className="image">AVATAR</div>
              {usertype === "admin" && (
                <React.Fragment>
                  <div className="password">PASSWORD</div>
                  <div className="telegram">TELEGRAM</div>
                  <div className="nickname">NICK NAME</div>
                </React.Fragment>
              )}

              <div className="state">STATE</div>
              <div className="time">TIME</div>
            </div>
            <div className="tool">OPERATION</div>
          </div>
          <div className="body">
            {userList.length > 0 ? (
              <React.Fragment>
                {userList.map((item, index) => {
                  return (
                    <div className="li" key={index}>
                      <div className="left">
                        <div className="name">{item.username}</div>
                        <div className="image">
                          {item.image !== null ? (
                            <div className="img">
                              <img src={BASE_URL + item.image} alt="" />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        {usertype === "admin" && (
                          <React.Fragment>
                            <div className="password">{item.password}</div>
                            <div className="telegram">{item.telegramId}</div>
                            <div className="nickname">{item.nickname}</div>
                          </React.Fragment>
                        )}
                        <div
                          className={`state ${item.state > 0 ? "on" : "off"}`}
                        >
                          {item.state > 0 ? "ON" : "OFF"}
                        </div>
                        <div className="time">
                          {moment(item.createtime * 1000).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </div>
                      </div>
                      <div className="tool">
                        <EditIcon
                          size={25}
                          className="icon"
                          color="#285db1"
                          onClick={() => this.routeAddUser(item.id)}
                        />
                        {usertype === "admin" && (
                          <DeleteIcon
                            size={25}
                            className="icon"
                            color="#285db1"
                            onClick={() => this.handlePreviewAlert(item.id)}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </React.Fragment>
            ) : (
              <div className="no-content">
                <NoContent size={200} />
              </div>
            )}
          </div>
        </div>
        <div className="footer">
          <Pagination
            size="small"
            total={this.state.count * this.state.per_page}
            // count={count}
            pageSize={this.state.per_page}
            current={this.state.page}
            onChange={(page, pageSize) =>
              this.handlePageChange(null, page, pageSize)
            }
            showQuickJumper
            variant="outlined"
            color="standard"
          />
        </div>
      </React.Fragment>
    );
  }
}

export default User;
