import React from "react";

const Company = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#fff",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="9318"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M940.032 862.72H890.88V303.104c0-16.384-9.728-30.72-24.576-37.888l-245.248-128c-13.824-6.144-29.184-6.144-43.008 0-15.36 6.144-25.088 20.992-24.576 101.888V926.72h-49.152V171.008c0-82.944 0-95.232-18.432-101.888-15.872-6.656-33.28-6.656-49.152 0L156.16 217.088c-14.848 7.168-24.576 22.528-24.576 38.912v607.232h-49.152c-12.288 0-18.432 6.656-18.432 12.8V947.2c0 6.144 6.144 12.8 18.432 12.8h857.6c5.632 0 17.408-6.656 18.432-12.8v-70.656c1.536-7.68-11.776-13.824-18.432-13.824zM364.032 772.608c0 7.168-5.632 12.8-12.8 12.8H282.112c-7.168 0-12.8-5.632-12.8-12.8v-59.904c0-7.168 5.632-12.8 12.8-12.8h70.656c7.168 0 12.8 5.632 12.8 12.8l-1.536 59.904z m0-180.736c0 7.168-5.632 12.8-12.8 12.8H282.112c-7.168 0-12.8-5.632-12.8-12.8v-60.928c0-7.168 5.632-12.8 12.8-12.8h70.656c7.168 0 12.8 5.632 12.8 12.8l-1.536 60.928z m0-180.224c0 7.168-5.632 12.8-12.8 12.8H282.112c-7.168 0-12.8-5.632-12.8-12.8v-61.44c0-7.168 5.632-12.8 12.8-12.8h70.656c7.168 0 12.8 5.632 12.8 12.8l-1.536 61.44z m403.968 360.448c0 7.168-5.632 12.8-12.8 12.8h-70.656c-7.168 0-12.8-5.632-12.8-12.8V711.68c0-7.168 5.632-12.8 12.8-12.8h70.656c7.168 0 12.8 5.632 12.8 12.8v60.416z m0-180.736c0 7.168-5.632 12.8-12.8 12.8h-70.656c-7.168 0-12.8-5.632-12.8-12.8v-60.416c0-7.168 5.632-12.8 12.8-12.8h70.656c7.168 0 12.8 5.632 12.8 12.8v60.416z m0-180.224c0 7.168-5.632 12.8-12.8 12.8h-70.656c-7.168 0-12.8-5.632-12.8-12.8V350.208c0-7.168 5.632-12.8 12.8-12.8h70.656c7.168 0 12.8 5.632 12.8 12.8v60.928z"
      fill={color}
      p-id="9319"
    ></path>
  </svg>
);


export default Company;