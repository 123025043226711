import React from "react";

const CloseIcon = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#423939",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="36864"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M667.136 512l284.501333-284.501333A109.738667 109.738667 0 0 0 796.501333 72.362667L512 356.864 227.498667 72.362667A109.738667 109.738667 0 1 0 72.362667 227.498667L356.864 512 72.362667 796.501333a109.738667 109.738667 0 0 0 155.136 155.136L512 667.136l284.501333 284.501333a109.738667 109.738667 0 0 0 155.136-155.136L667.136 512z"
      fill={color}
      p-id="36865"
    ></path>
  </svg>
);

export default CloseIcon;
