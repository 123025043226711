import React, { Component } from "react";
import "../style/entrance.scss";
import { setAPIAuthorizationToken } from "../apis/API";
import LogOutIcon from "../icon/LogOutIcon";
import HamburgerIcon from "../icon/HamburgerIcon";
import AllowRight from "../icon/AllowRight";
import cookie from "react-cookies";
import {
  BrowserRouter as Router,
  Switch,
  HashRouter,
  Route,
  Redirect,
} from "react-router-dom";
import { BASE_URL } from "../constants/ApiEndpointConstants";
import LeftBar from "./common/LeftBar";
import Home from "./Home";
import User from "./User";
import Company from "./Company";
import Order from "./Order";
import AddUser from "./AddUser";
import AddCompany from "./AddCompany";
import Billing from "./Billing";
import NotifyIcon from "../icon/NotifyIcon";
import Notice from "../icon/Notice";
import Product from "./Product";
import Sku from "./Sku";
import Country from "./Country";
import AddCountry from "./AddCountry";
import Category from "./Category";
import AddCategory from "./AddCategory";
import AddSku from "./AddSku";
import EditOrder from "./EditOrder";
import News from "./News";
import AddNews from "./AddNews";
import Comment from "./Comment";
import AddComment from "./AddComment";
import EditBilling from "./EditBilling";
import VIP from "./VIP";
import AddVIP from "./AddVIP";
import * as GetBillingListAPI from "../apis/GetBillingListAPI";
import * as GetOrderListAPI from "../apis/GetOrderListAPI";

import NoContent from "../icon/NoContent";
import Process from "../icon/Processing";
import PaySuucess from "../icon/PaySuccess";
import PayError from "../icon/PayError";
import Pending from "../icon/Pending";

const iconColor = "#424242";

class Entrance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reduce: true,
      username: "",
      orderBox: false,
      billingBox: false,
      orderList: [],
      billingList: [],
    };
    this.routeLogin = this.routeLogin.bind(this);
    this.clickReduce = this.clickReduce.bind(this);
    this.showNotifyBox = this.showNotifyBox.bind(this);
    this.routePage = this.routePage.bind(this);
  }

  componentDidMount() {
    let param = cookie.load("token");
    if (param) {
      this.setState({ username: param.user.username });
      setAPIAuthorizationToken(param.token);
      this.getBillingList();
      this.getOrderList();
    } else {
      const { history } = this.props;
      history.push("/user-register-login");
    }
  }

  clickReduce() {
    this.setState({ reduce: !this.state.reduce });
  }
  searchInput(e) {
    this.setState({ searchValue: e.target.value });
  }
  routeLogin() {
    const { history } = this.props;
    let param = cookie.load("token");
    console.log(param);
    if (param.user.roleType === "client") {
      cookie.remove("token");
      history.push("/user-register-login");
    } else {
      cookie.remove("token");
      history.push("/Login");
    }
    window.dispatchEvent(
      new CustomEvent("showAlert", {
        detail: "Successfully withdrawn",
      })
    );
  }

  showNotifyBox(type) {
    switch (type) {
      case "order":
        this.setState({ orderBox: !this.state.orderBox }, () => {
          this.getBillingList();
        });
        break;
      case "billing":
        this.setState({ billingBox: !this.state.billingBox }, () => {
          this.getOrderList();
        });
        break;
      default:
        break;
    }
  }

  getBillingList() {
    GetBillingListAPI.getBillingListNoPage("processing").then((res) => {
      var data = res.data.data;
      console.log(data);
      if (data) {
        this.setState({ billingList: data.list });
      }
    });
  }

  getOrderList() {
    GetOrderListAPI.getOrderListNoPage().then((res) => {
      var data = res.data.data;
      console.log(data);
      if (data) {
        this.setState({ orderList: data.list });
      }
    });
  }

  routePage(id, type) {
    let { history } = this.props;
    switch (type) {
      case "order":
        history.push(`/EditOrder/${id}`);
        this.showNotifyBox(type);
        break;
      case "billing":
        history.push(`/EditBilling/${id}`);
        this.showNotifyBox(type);
        break;
      default:
        break;
    }
  }

  render() {
    const { reduce, username, orderBox, billingBox, billingList, orderList } =
      this.state;
    let { location } = this.props;
    return (
      <React.Fragment>
        <div className="body-container">
          {/* 左 */}
          <div className={`left-bar ${reduce ? "reduce-left" : ""}`}>
            <LeftBar {...this.props} />
          </div>
          {/* 右 */}
          <div className={`right-bar ${reduce ? "reduce-right" : ""}`}>
            <header>
              <div className="head-bar">
                <div className="nav-header">
                  <div className="header-left">
                    {reduce ? (
                      <AllowRight
                        size={25}
                        color={iconColor}
                        onClick={this.clickReduce}
                        className="icon"
                      />
                    ) : (
                      <HamburgerIcon
                        size={30}
                        color={iconColor}
                        onClick={this.clickReduce}
                        className="icon"
                      />
                    )}
                    <h1>
                      {location.pathname === "/Home" && "Dashboard"}
                      {location.pathname === "/User" && "User"}
                      {location.pathname === "/Company" && "Company"}
                      {location.pathname === "/Sku" && "Sku"}
                      {location.pathname === "/Country" && "Country"}
                      {location.pathname === "/Order" && "Order"}
                      {location.pathname === "/Product" && "Product"}
                      {location.pathname === "/Billing" && "Billing"}
                      {location.pathname === "/Category" && "Category"}
                      {location.pathname === "/News" && "New"}
                      {location.pathname === "/VIP" && "VIP"}
                      {location.pathname.indexOf("/Comment") >= 0 && "Comment"}
                      {location.pathname.indexOf("/AddComment") >= 0 &&
                        "AddComment"}
                      {location.pathname.indexOf("/EditOrder") >= 0 &&
                        "EditOrder"}
                      {location.pathname.indexOf("/EditBilling") >= 0 &&
                        "EditBilling"}
                      {location.pathname.indexOf("/AddUser") >= 0 && "AddUser"}
                      {location.pathname.indexOf("/AddSku") >= 0 && "AddSku"}
                      {location.pathname.indexOf("/AddNews") >= 0 && "AddNews"}
                      {location.pathname.indexOf("/AddCategory") >= 0 &&
                        "AddCategory"}
                      {location.pathname.indexOf("/AddCompany") >= 0 &&
                        "AddCompany"}
                      {location.pathname.indexOf("/AddCountry") >= 0 &&
                        "AddCountry"}
                      {location.pathname.indexOf("/AddVIP") >= 0 && "AddVIP"}
                    </h1>
                  </div>
                  <div className="header-right">
                    <div className="head-li">
                      <NotifyIcon
                        size={30}
                        color={iconColor}
                        className="head-icon"
                        onClick={() => this.showNotifyBox("order")}
                      />
                      <div className="tip"></div>
                      <div
                        className={`order-box ${
                          orderBox ? "orderBox-show" : ""
                        }`}
                      >
                        <div className="square"></div>
                        {orderList.length > 0 ? (
                          <div className="notice-content">
                            {orderList.map((item, index) => {
                              return (
                                <div
                                  className="notice-li"
                                  key={index}
                                  onClick={() =>
                                    this.routePage(item.id, "order")
                                  }
                                >
                                  <div className="left">
                                    {item.status === "pending" && (
                                      <Pending size={24} />
                                    )}
                                    {item.status === "processing" && (
                                      <Process size={24} />
                                    )}
                                    {item.status === "successed" && (
                                      <PaySuucess size={30} color="#196d1f" />
                                    )}
                                    {item.status === "failed" && (
                                      <PayError size={28} color="#d81e06" />
                                    )}
                                    <div className="li-value">
                                      {item.username}
                                    </div>
                                  </div>
                                  <div className="li-value">
                                    <img
                                      src={BASE_URL + item.producticon}
                                      alt=""
                                    />
                                  </div>
                                  <div className="li-value">
                                    <span>${item.price}</span>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <div className="no-content">
                            <NoContent size={100} />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="head-li">
                      <Notice
                        size={30}
                        color={iconColor}
                        className="head-icon"
                        onClick={() => this.showNotifyBox("billing")}
                      />
                      <div className="tip"></div>
                      <div
                        className={`billing-box ${
                          billingBox ? "billing-show" : ""
                        }`}
                      >
                        {" "}
                        <div className="square"></div>
                        {billingList.length > 0 ? (
                          <div className="notice-content">
                            {billingList.map((item, index) => {
                              return (
                                <div
                                  className="notice-li"
                                  key={index}
                                  onClick={() =>
                                    this.routePage(item.id, "billing")
                                  }
                                >
                                  <div className="left">
                                    {item.status === "processing" && (
                                      <Process size={24} />
                                    )}
                                    <div className="li-value">
                                      {item.username}
                                    </div>
                                  </div>
                                  <div className="li-value">
                                    <span>${item.amount}</span>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <div className="no-content">
                            <NoContent size={100} />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="profile">
                      <div className="left">
                        <div className="user-text">
                          <p>Hi,</p>
                          <h6>{username}</h6>
                        </div>
                      </div>
                      {/* logout */}
                      <div className="logout" onClick={this.routeLogin}>
                        <LogOutIcon
                          size={28}
                          color={iconColor}
                          className="logout-icon"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <div className="page-contain">
              <Switch>
                <Route path="/AddVIP" component={AddVIP}></Route>
                <Route path="/EditBilling" component={EditBilling}></Route>
                <Route path="/AddComment" component={AddComment}></Route>
                <Route path="/AddNews" component={AddNews}></Route>
                <Route path="/EditOrder" component={EditOrder}></Route>
                <Route path="/AddSku" component={AddSku}></Route>
                <Route path="/AddCategory" component={AddCategory}></Route>
                <Route path="/AddCountry" component={AddCountry}></Route>
                <Route path="/AddCompany" component={AddCompany}></Route>
                <Route path="/AddUser" component={AddUser}></Route>
                <Route path="/VIP" component={VIP}></Route>
                <Route path="/Comment" component={Comment}></Route>
                <Route path="/News" component={News}></Route>
                <Route path="/Category" component={Category}></Route>
                <Route path="/Billing" component={Billing}></Route>
                <Route path="/Country" component={Country}></Route>
                <Route path="/Sku" component={Sku}></Route>
                <Route path="/Product" component={Product}></Route>
                <Route path="/Order" component={Order}></Route>
                <Route path="/Company" component={Company}></Route>
                <Route path="/User" component={User}></Route>
                <Route path="/Home" component={Home}></Route>
                <Redirect exact to="/Home" />
              </Switch>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Entrance;
