import React from "react";

const Charge = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#272636",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="49409"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M951.7 204.1c0-30.7-24.9-55.6-55.6-55.6L118 148.5c-30.7 0-55.6 24.9-55.6 55.6l0 111.2 889.2 0L951.6 204.1z"
      fill={color}
      p-id="49410"
    ></path>
    <path
      d="M951.7 370.8 62.5 370.8l0 444.6 0 4c0 28.5 23.1 51.6 51.6 51.6l4 0 632.1 0 149.9 0c28.5 0 51.6-23.1 51.6-51.6L951.7 674.2c0 0 0 0 0 0L951.7 370.8 951.7 370.8zM382.1 704.3 159.8 704.3c-15.3 0-27.8-12.4-27.8-27.8 0-15.4 12.4-27.8 27.8-27.8l222.3 0c15.3 0 27.8 12.4 27.8 27.8C409.9 691.8 397.5 704.3 382.1 704.3zM437.7 593.1 159.8 593.1c-15.3 0-27.8-12.4-27.8-27.8 0-15.3 12.4-27.8 27.8-27.8l277.9 0c15.3 0 27.8 12.4 27.8 27.8C465.5 580.7 453 593.1 437.7 593.1zM648.4 593.1l-55.6 0c-15.4 0-27.8-12.4-27.8-27.8 0-15.3 12.4-27.8 27.8-27.8l55.6 0c15.3 0 27.8 12.4 27.8 27.8C676.2 580.7 663.8 593.1 648.4 593.1z"
      fill={color}
      p-id="49411"
    ></path>
  </svg>
);

export default Charge;
