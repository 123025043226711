import React from "react";
const ChartIcon = ({
  size = 24,
  color = "#ffffff",
  viewBox = "0 0 24 24",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="2424"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M576 448 576 0c265.088 0 448 182.912 448 448L576 448zM480 1024C214.912 1024 0 809.088 0 544 0 254.912 210.912 64 512 64l0 448 448 0C960 777.088 745.088 1024 480 1024z"
      fill={color}
      p-id="2425"
    ></path>
  </svg>
);
export default ChartIcon;
