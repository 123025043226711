import axios from "axios";
import ApiEndpointConstants from "../constants/ApiEndpointConstants";
import { BASE_URL } from "../constants/ApiEndpointConstants";

export const upload = async (e, token) => {
  const formData = new FormData();
  const file = e.target.files[0]; // 只取第一个文件
  if (!file) {
    console.warn("No file selected");
    return null;
  }

  formData.append("file", file);

  try {
    const res = await axios({
      url: BASE_URL + ApiEndpointConstants.UPLOAD,
      method: "post",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        token: `${token}`, // 在头部设置 Token
      },
    });

    console.log("Upload successful");
    console.log(res);
    console.log(res.data.data.url);
    return res.data.data.url; // 返回上传成功的 URL
  } catch (error) {
    console.error("Upload failed", error);
    return null; // 如果失败返回 null
  }
};
