import api from "../apis/API";
import ApiEndpointConstants from "../constants/ApiEndpointConstants";

export const getNewsList = (page, per_page) => {
  return api.post(`${ApiEndpointConstants.GET_STORYLIST}`, { page, per_page });
};

export const getNewsListNoPage = () => {
  return api.post(`${ApiEndpointConstants.GET_STORYLIST_NOPAGE}`);
};
