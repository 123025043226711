import React from "react";

const EditOrder = ({
    viewBox = '0 0 24 24',
    size = 24,
    color = '#333',
    ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="5014"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M369.470301 757.192563a24.217949 24.217949 0 0 1 24.216949 24.089949v1.770996a23.966949 23.966949 0 0 1-23.96095 23.97195l-0.255999-0.005H160.523741a24.217949 24.217949 0 0 1-24.217949-23.96695v-1.770996c0-13.311972 10.782977-24.089949 24.08995-24.089949H369.470301z m13.311972-198.429582a24.217949 24.217949 0 0 1 24.216948 24.216949v2.032996a24.089949 24.089949 0 0 1-24.216948 23.57795H173.835713a24.217949 24.217949 0 0 1-24.217949-24.089949v-1.269997a23.961949 23.961949 0 0 1 23.96195-23.96095h0.255999l208.94656-0.506999z m243.685486-198.424581a24.083949 24.083949 0 0 1 24.216948 23.960949v1.776997a24.083949 24.083949 0 0 1-24.089949 24.089949H171.424718a24.211949 24.211949 0 0 1-24.211949-24.089949v-1.776997a24.083949 24.083949 0 0 1 24.211949-23.960949h455.044041z m-48.429898 555.460828a9.69198 9.69198 0 0 1-2.789994-1.391997l0.756998 1.903996h2.027996v-0.511999zM268.672513 199.698739h33.21893a33.34593 33.34593 0 0 0 33.34593-33.34593V33.35109A33.35193 33.35193 0 0 0 301.891443 0.00416h-33.21893a33.473929 33.473929 0 0 0-33.847928 33.08993v133.258719a33.34593 33.34593 0 0 0 33.346929 33.34593h0.500999z m366.038228 0h33.35093a33.21793 33.21793 0 0 0 33.21893-33.21793V33.35109A33.22893 33.22893 0 0 0 668.184671 0.00416h-33.47393a33.21793 33.21793 0 0 0-33.21393 33.21893v133.129719a33.22393 33.22393 0 0 0 33.09593 33.34593h0.118z"
      fill={color}
      p-id="5015"
    ></path>
    <path
      d="M238.116578 52.878048v50.211895l90.905808 0.249999V52.879048h-90.905808z m367.051225 0v51.219892l91.033808 0.129V52.878048h-91.033808z m-523.762895 0a58.849876 58.849876 0 0 0-43.739907 18.636961 62.140869 62.140869 0 0 0-18.385962 45.265905V932.280193a60.737872 60.737872 0 0 0 18.257962 44.757906 59.478875 59.478875 0 0 0 43.867907 18.257962h266.254439a24.349949 24.349949 0 0 0 24.216949-24.216949 24.467948 24.467948 0 0 0-24.216949-24.339949L69.990932 944.327168V102.577944h103.966781a25.359947 25.359947 0 1 0 0-50.718893l-92.553805 1.019997z m811.572288 18.508961a57.189879 57.189879 0 0 0-43.104909-18.513961h-88.754812a25.359947 25.359947 0 0 0 0 50.718893h100.289788v285.904397a24.217949 24.217949 0 0 0 24.217949 24.217949 24.334949 24.334949 0 0 0 24.212949-24.217949V116.781914a63.354866 63.354866 0 0 0-16.859965-45.393905z m-524.264894 6.722986a25.359947 25.359947 0 0 0 25.359947 25.358947h146.184691a25.359947 25.359947 0 1 0 0-50.718893h-146.189691a25.359947 25.359947 0 0 0-25.354947 25.359946zM990.041992 589.922916c21.283955-21.283955 21.283955-55.853882 0-77.229837l-38.568919-38.568919c-21.282955-21.288955-55.853882-21.288955-77.229837 0l-37.389921 37.386921 115.797756 115.797756 37.390921-37.385921zM483.901059 864.825336l-19.00996 110.432767c-2.451995 14.28397 9.916979 26.653944 24.196949 24.104949l110.161768-19.18996 0.275999 0.281L912.822155 667.070753 797.013399 551.348997 483.72206 864.640336l0.179999 0.185z"
      fill={color}
      p-id="5016"
    ></path>
  </svg>
);


export default EditOrder;