import React from "react";

const AddCategory = ({
    viewBox = '0 0 24 24',
    size = 24,
    color = '#fff',
    ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="12852"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M144.510472 6.68102h183.728061a137.829451 137.829451 0 0 1 137.762641 137.829452v183.728061a137.829451 137.829451 0 0 1-137.762641 137.762641h-183.728061A137.829451 137.829451 0 0 1 6.68102 328.171723v-183.728061A137.829451 137.829451 0 0 1 144.510472 6.68102z m0 73.491225a64.338227 64.338227 0 0 0-64.338227 64.338227v183.728061a64.338227 64.338227 0 0 0 64.338227 64.338227h183.728061a64.338227 64.338227 0 0 0 64.338227-64.338227v-183.728061a64.338227 64.338227 0 0 0-64.338227-64.338227z m0 477.75977h183.728061a137.829451 137.829451 0 0 1 137.762641 137.829452v183.728061a137.829451 137.829451 0 0 1-137.829451 137.829452h-183.728061a137.829451 137.829451 0 0 1-137.829452-137.829452v-183.728061a137.829451 137.829451 0 0 1 137.829452-137.829452z m0 73.491225a64.338227 64.338227 0 0 0-64.338227 64.338227v183.728061a64.338227 64.338227 0 0 0 64.338227 64.338227h183.728061a64.338227 64.338227 0 0 0 64.338227-64.338227v-183.728061a64.338227 64.338227 0 0 0-64.338227-64.338227h-183.728061zM695.761467 6.61421h183.728061a137.829451 137.829451 0 0 1 137.829452 137.829452v183.728061a137.829451 137.829451 0 0 1-137.762642 137.829451h-183.728061a137.829451 137.829451 0 0 1-137.829451-137.829451v-183.728061A137.829451 137.829451 0 0 1 695.761467 6.68102z m0 73.491225a64.338227 64.338227 0 0 0-64.338227 64.338227v183.728061a64.338227 64.338227 0 0 0 64.338227 64.338227h183.728061a64.338227 64.338227 0 0 0 64.338227-64.338227v-183.728061a64.338227 64.338227 0 0 0-64.338227-64.338227z m-73.491225 749.610491h321.557513a36.745612 36.745612 0 0 0 0-73.491224H622.270242a36.745612 36.745612 0 0 0 0 73.491224z"
      fill={color}
      p-id="12853"
    ></path>
    <path
      d="M762.103999 655.675344v321.557513a36.745612 36.745612 0 0 0 73.491225 0V655.675344a36.745612 36.745612 0 0 0-73.491225 0z"
      fill={color}
      p-id="12854"
    ></path>
  </svg>
);


export default AddCategory;