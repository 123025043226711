import React, { useState, useEffect } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";

import "../../style/common/map.scss";
import { getRandomInt } from "../../utils/RamdomMath";

import { geoMercator } from "d3-geo"; // 导入 d3 的 geoMercator 投影
const geoUrl = "./maps/countries-110m.json"; // 地图数据路径
const markers = [
  {
    name: "United States",
    coordinates: [-98.5795, 39.8283],
    user: getRandomInt(10, 20),
  },
  {
    name: "Canada",
    coordinates: [-106.3468, 56.1304],
    user: getRandomInt(10, 20),
  },
  {
    name: "United Kingdom",
    coordinates: [-3.436, 55.3781],
    user: getRandomInt(10, 20),
  },
  {
    name: "Australia",
    coordinates: [133.7751, -25.2744],
    user: getRandomInt(10, 20),
  },
  {
    name: "Brazil",
    coordinates: [-51.9253, -14.235],
    user: getRandomInt(10, 20),
  },
  {
    name: "Russia",
    coordinates: [105.3188, 61.524],
    user: getRandomInt(10, 20),
  },
];

const Map = () => {
  const [highlightedRegion, setHighlightedRegion] = useState(null);
  const [tooltipContent, setTooltipContent] = useState("");
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const handleMouseEnter = (geo, e) => {
    setHighlightedRegion(geo.properties.name);
    setTooltipContent(geo.properties.name);
    setTooltipPosition({ x: 10, y: 10 });
  };

  const handleMouseLeave = () => {
    setHighlightedRegion(null);
    setTooltipContent("");
  };

  return (
    <React.Fragment>
      <div style={{ position: "relative", width: "100%", height: "auto" }}>
        <ComposableMap
          projection="geoMercator" // 投影类型
          projectionConfig={{ scale: 80 }} // 地图缩放
          // 使地图适应容器
          // width={600}
          height={500}
        >
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies
                .filter((geo) => geo.properties.name !== "Antarctica") // 直接在这里过滤
                .map((geo) => {
                  const isHighlighted =
                    geo.properties.name === highlightedRegion; // 判断是否高亮

                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      onMouseEnter={(e) => handleMouseEnter(geo, e)} // 鼠标悬停时高亮区域
                      onMouseLeave={handleMouseLeave} // 鼠标离开时取消高亮
                      style={{
                        default: {
                          fill: "#EAEAEC", // 默认颜色
                          outline: "none",
                        },
                        hover: {
                          fill: isHighlighted ? "#999" : "#EAEAEC", // 高亮颜色
                          outline: "none",
                        },
                        pressed: {
                          fill: "#E42", // 按下时颜色
                          outline: "none",
                        },
                      }}
                    />
                  );
                })
            }
          </Geographies>
          {/* 渲染城市标记 */}
          {markers.map(({ name, coordinates, user }) => {
            return (
              <Marker key={name} coordinates={coordinates}>
                <circle
                  r={4}
                  fill="rgb(118, 137, 231)"
                  className="map-cricle"
                />
                <text textAnchor="middle" y={-15} style={{ fontSize: 8 }}>
                  {name} <br /> ( {user} )
                </text>
              </Marker>
            );
          })}
        </ComposableMap>
        {/* Tooltip */}
        {tooltipContent && (
          <div
            style={{
              position: "absolute",
              top: tooltipPosition.y,
              left: tooltipPosition.x,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "#FFF",
              padding: "10px",
              borderRadius: "5px",
              pointerEvents: "none",
              fontSize: "14px",
              border: "1px",
            }}
          >
            {tooltipContent} {/* 显示高亮国家名称 */}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Map;
