import React, { Component } from "react";
import "../../style/common/alertBox.scss";
import CloseIcon from "../../icon/CloseIcon";

class AlertBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertShow: this.props.alertShow || false,
    };
    this.toggleAlertShow = this.toggleAlertShow.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    console.log("1111");
    console.log(this.props);
    const { confirmHandler } = this.props;
    confirmHandler && confirmHandler();
    this.toggleAlertShow();
  }

  toggleAlertShow() {
    this.setState({ alertShow: !this.state.alertShow });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.alertShow !== this.props.alertShow) {
      this.setState({ alertShow: this.props.alertShow });
    }
  }

  render() {
    const { alertShow } = this.state;
    return (
      <React.Fragment>
        <div className={`alert-mask ${alertShow ? "alert-show" : ""}`}>
          <div className="alert-box">
            <CloseIcon
              size={20}
              className="close icon"
              onClick={this.toggleAlertShow}
            />
            <div className="title">
              Are you sure you want to perform this action?
            </div>
            <div className="btn-row">
              <div className="btn" onClick={this.handleClick}>
                Yes
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AlertBox;
