import api from "../apis/API";
import ApiEndpointConstants from "../constants/ApiEndpointConstants";

export const getBillingList = (page, per_page) => {
  return api.post(`${ApiEndpointConstants.GET_BILLINGLIST}`, {
    page,
    per_page,
  });
};
export const getBillingListNoPage = (status) => {
  return api.post(`${ApiEndpointConstants.GET_BILLINGLIST_NOPAGE}`,{status});
};
