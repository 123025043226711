import React, { Component } from "react";
import CloseIcon from "../icon/CloseIcon";
import "../style/addNews.scss";
import Loading from "./common/Loading";
import Submit from "../icon/SubmitIcon";
import * as SubmitCommentAPI from "../apis/SubmitCommentAPI";
import * as GetCommentAPI from "../apis/GetCommentAPI";
import { BASE_URL } from "../constants/ApiEndpointConstants";
import * as GetNewsListAPI from "../apis/GetNewsListAPI";
import FileIcon from "../icon/FileIcon";
import { upload } from "../utils/UploadFile";
import cookie from "react-cookies";
import { img } from "@douyinfe/semi-ui/lib/es/markdownRender/components";
import { Select } from "antd";
import Edit from "./common/Edit";
import EditorMix from "./common/EditorMix";
import { setAPIAuthorizationToken } from "../apis/API";

class AddComment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      logo: null,
      id: 0,
      isClick: true,
      description: "",
      nickname: "",
      tieziid: "",
      newsList: [],
      news: null,
    };
    this.inputChange = this.inputChange.bind(this);
    this.submit = this.submit.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.back = this.back.bind(this);
    this.clickToMove = this.clickToMove.bind(this);
    this.getNewsList = this.getNewsList.bind(this);
  }

  EditRef = React.createRef();

  componentDidMount() {
    let param = cookie.load("token");
    setAPIAuthorizationToken(param.token);
    let { location } = this.props;
    if (location.pathname.indexOf("/AddComment/") >= 0) {
      let id = location.pathname.replace("/AddComment/", "");
      this.toggleShow();
      GetCommentAPI.getComment(id)
        .then((res) => {
          this.toggleShow();
          console.log(res);
          var data = res.data.data;
          if (data) {
            this.setState({
              id: parseInt(id),
              news: {
                value: data.tieziid,
                label: data.tiezititle,
              },
              nickname: data.nickname,
              description: data.description,
              logo: data.logo,
            });
          }
        })
        .catch((err) => {
          this.toggleShow();
        });
    }
    this.getNewsList();
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  getNewsList() {
    GetNewsListAPI.getNewsListNoPage().then((res) => {
      var data = res.data.data;
      if (data) {
        let newsList = data.list.map((item) => {
          return {
            value: item.id,
            label: item.title,
          };
        });
        this.setState({ newsList });
      }
    });
  }

  async inputChange(e, type) {
    let param = cookie.load("token");
    switch (type) {
      case "title":
        this.setState({ title: e.target.value });
        break;
      case "nickname":
        this.setState({ nickname: e.target.value });
        break;
      case "logo":
        const url = await upload(e, param.token);
        if (url) {
          this.setState({ logo: url });
        }
        break;
      default:
        break;
    }
  }

  submit() {
    let { id, news, logo, description, nickname } = this.state;
    if (this.EditRef) {
      console.log(this.EditRef);
      description = this.EditRef.current.getValue();
      let param = {
        id,
        tieziid: news,
        logo,
        description,
        nickname,
      };

      this.toggleShow();
      SubmitCommentAPI.submitComment(param)
        .then((res) => {
          this.toggleShow();
          var code = res.data.code;
          var msg = res.data.msg;
          console.log(msg);
          if (code === 1) {
            if (id !== 0) {
              window.dispatchEvent(
                new CustomEvent("showAlert", {
                  detail: "Modify user successfully",
                })
              );
              this.back();
            } else {
              window.dispatchEvent(
                new CustomEvent("showAlert", {
                  detail: "Add User Successfully",
                })
              );
              this.back();
            }
            return;
          }
          window.dispatchEvent(
            new CustomEvent("showAlert", {
              detail: msg,
            })
          );
        })
        .catch((err) => {
          this.toggleShow();
        });
    }
  }

  back() {
    let { history } = this.props;
    history.goBack();
  }

  clickToMove() {
    let { isClick, status } = this.state;
    status = isClick ? 0 : 1;
    this.setState({ isClick: !this.state.isClick, status });
  }

  handleChange = (selected, type) => {
    // 更新已选择的选项
    switch (type) {
      case "country":
        this.setState({ country: selected });
        break;
      case "sku":
        this.setState({ sku: selected });
        break;
      case "category":
        this.setState({ category: selected });
        break;
      case "news":
        this.setState({ news: selected });
        break;
      default:
        break;
    }
  };

  render() {
    const { title, logo, isClick, description, nickname, newsList, news } =
      this.state;
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <div className="addNews-page">
          <CloseIcon
            size={30}
            className="icon close"
            color="#5864ff"
            onClick={this.back}
          />
          <div className="row">
            <div className="title">News:</div>
            <Select
              options={newsList}
              value={news || ""}
              onChange={(selected) => this.handleChange(selected, "news")}
              className="control"
              placeholder="Select A News"
            />
          </div>
          <div className="row">
            <div className="title">Commentator :</div>
            <input
              type="text"
              value={nickname || ""}
              onChange={(e) => this.inputChange(e, "nickname")}
            />
          </div>
          <div className="row">
            <div className="title">Icon :</div>
            <div className="file">
              <input
                type="file"
                placeholder="image"
                className="file-input"
                onChange={(e) => {
                  this.inputChange(e, "logo");
                }}
              />
              {logo !== null ? (
                <div className="img">
                  <img src={BASE_URL + logo} />
                </div>
              ) : (
                <React.Fragment>
                  <FileIcon size={50} color="#5864ff" />
                  <p className="p">
                    Drop your image here,or browse <br />
                    JPG,PNG and GIF files are allowed
                  </p>
                </React.Fragment>
              )}
            </div>
          </div>
          <div className="row">
            <div className="title">Description : </div>
            <EditorMix
              {...this.props}
              ref={this.EditRef}
              value={description || ""}
            />
          </div>
          <div className="footer">
            <Submit
              size={40}
              color="#5864ff"
              className="icon"
              onClick={this.submit}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddComment;
