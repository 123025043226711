import React, { Component } from "react";
import CloseIcon from "../icon/CloseIcon";
import "../style/addCountry.scss";
import Loading from "./common/Loading";
import Submit from "../icon/SubmitIcon";
import * as SubmitCountryAPI from "../apis/SubmitCountryAPI";
import * as GetCountryAPI from "../apis/GetCountryAPI";
import { BASE_URL } from "../constants/ApiEndpointConstants";

import FileIcon from "../icon/FileIcon";
import { upload } from "../utils/UploadFile";
import cookie from "react-cookies";
import { img } from "@douyinfe/semi-ui/lib/es/markdownRender/components";
import { Select } from "antd";
import ReactFlagsSelect from "react-flags-select";
import { foundCountry } from "../utils/CountryMap";
import { setAPIAuthorizationToken } from "../apis/API";

class AddCountry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      countrycode: null,
      country: "",
      id: 0,
      isClick: true,
      status: 1,
    };
    this.setSelected = this.setSelected.bind(this);
    this.submit = this.submit.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.back = this.back.bind(this);
    this.clickToMove = this.clickToMove.bind(this);
  }

  componentDidMount() {
    let param = cookie.load("token");
    setAPIAuthorizationToken(param.token);
    let { location } = this.props;
    if (location.pathname.indexOf("/AddCountry/") >= 0) {
      let id = location.pathname.replace("/AddCountry/", "");
      this.toggleShow();
      GetCountryAPI.getCountry(id).then((res) => {
        this.toggleShow();
        console.log(res);
        var data = res.data.data;
        if (data) {
          this.setState({
            id: parseInt(id),
            countrycode: data.countrycode,
            status: data.status,
            isClick: data.status > 0 ? true : false,
            country: foundCountry(data.countrycode),
          });
        }
      }).catch((err)=>{
        this.toggleShow();
      });;
    }
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  setSelected(code) {
    this.setState({ countrycode: code, country: foundCountry(code) });
  }
  submit() {
    const { id, country, countrycode ,status} = this.state;
    this.toggleShow();
    let param = {
      id,
      country,
      countrycode,
      status
    };
    console.log(param);
    SubmitCountryAPI.submitCountry(param).then((res) => {
      this.toggleShow();
      var code = res.data.code;
      var msg = res.data.msg;
      console.log(msg);
      if (code === 1) {
        if (id !== 0) {
          window.dispatchEvent(
            new CustomEvent("showAlert", {
              detail: "Modify country successfully",
            })
          );
          this.back();
        } else {
          window.dispatchEvent(
            new CustomEvent("showAlert", {
              detail: "Add country Successfully",
            })
          );
          this.back();
        }
        return;
      }
      window.dispatchEvent(
        new CustomEvent("showAlert", {
          detail: msg,
        })
      );
    }).catch((err)=>{
      this.toggleShow();
    });;
  }

  back() {
    let { history } = this.props;
    history.goBack();
  }

  clickToMove() {
    let { isClick, status } = this.state;
    status = isClick ? 0 : 1;
    this.setState({ isClick: !this.state.isClick, status });
  }

  render() {
    const { countrycode, isClick } = this.state;
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <div className="addCountry-page">
          <CloseIcon
            size={30}
            className="icon close"
            color="#5864ff"
            onClick={this.back}
          />

          <div className="row">
            <div className="title">Country :</div>
            <ReactFlagsSelect
              selected={this.state.countrycode}
              onSelect={(code) => this.setSelected(code)}
            />
          </div>
          <div className="row">
            <div className="statuBar">
              <p className="btn-p" onClick={this.clickToMove}>
                ON
              </p>
              <p className="btn-p" onClick={this.clickToMove}>
                OFF
              </p>
              <div className={`btn ${isClick ? "move" : ""}`}></div>
            </div>
          </div>
          <div className="footer">
            <Submit
              size={40}
              color="#5864ff"
              className="icon"
              onClick={this.submit}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddCountry;
