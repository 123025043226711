import React, { Component } from "react";
import CloseIcon from "../icon/CloseIcon";
import Loading from "./common/Loading";
import Submit from "../icon/SubmitIcon";
import "../style/addCompany.scss";
import Select from "react-select";
import * as GetCompanyAPI from "../apis/GetCompanyAPI";
import * as SubmitCompanyAPI from "../apis/SubmitCompanyAPI";
import * as GetCategoryListAPI from "../apis/GetCategoryListAPI";
import * as GetCountryListAPI from "../apis/GetCountryListAPI";
import * as GetSkuListAPI from "../apis/GetSkuListAPI";
import queryString from "query-string";
import { BASE_URL } from "../constants/ApiEndpointConstants";

import { upload } from "../utils/UploadFile";
import cookie from "react-cookies";
import { img } from "@douyinfe/semi-ui/lib/es/markdownRender/components";
// import { Select } from "antd";
import { setAPIAuthorizationToken } from "../apis/API";

import FileIcon from "../icon/FileIcon";

import { FlagIcon } from "react-flag-kit";

class AddCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      id: 0,
      category: null,
      country: null,
      sku: [],
      isClick: true,
      status: 1,
      categoryList: [],
      countryList: [],
      skuList: [],
      name: "",
      image: null,
    };
    this.toggleShow = this.toggleShow.bind(this);
    this.back = this.back.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.submit = this.submit.bind(this);
    this.clickToMove = this.clickToMove.bind(this);
    this.getCountryList = this.getCountryList.bind(this);
    this.getCategoryList = this.getCategoryList.bind(this);
    this.getSkuList = this.getSkuList.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    let param = cookie.load("token");
    setAPIAuthorizationToken(param.token);
    let { location } = this.props;
    if (location.pathname.indexOf("/AddCompany/") >= 0) {
      let id = location.pathname.replace("/AddCompany/", "");
      this.toggleShow();
      GetCompanyAPI.getCompany(id).then((res) => {
        this.toggleShow();
        console.log(res);
        var data = res.data.data;

        data.skulist = data.skulist.map((item) => {
          return {
            value: item.skuid,
            label: item.name,
            image: item.icon,
          };
        });
        console.log(data.skulist);
        if (data) {
          this.setState({
            id: parseInt(id),
            name: data.productname,
            country: {
              value: data.guojiaid,
              label: data.countrycode,
            },
            category: {
              value: data.menuid,
              label: data.menuname,
            },
            sku: data.skulist,
            status: data.status,
            isClick: data.status > 0 ? true : false,
            image: data.icon,
          });
        }
      }).catch((err)=>{
        this.toggleShow();
      });;
    }
    this.getCountryList();
    this.getCategoryList();
    this.getSkuList();
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  getCountryList() {
    GetCountryListAPI.getCountryListNoPage().then((res) => {
      var data = res.data.data;
      if (data) {
        let countryList = data.list.map((item) => {
          return {
            value: item.id,
            label: item.countrycode,
          };
        });
        this.setState({ countryList });
      }
    });
  }

  getCategoryList() {
    GetCategoryListAPI.getCategoryListNoPage().then((res) => {
      var data = res.data.data;
      if (data) {
        let categoryList = data.list.map((item) => {
          return {
            value: item.id,
            label: item.menuname,
          };
        });
        this.setState({ categoryList });
      }
    });
  }

  getSkuList() {
    GetSkuListAPI.getSkuListNoPage().then((res) => {
      var data = res.data.data;
      if (data) {
        let skuList = data.list.map((item) => {
          return {
            value: item.id,
            label: item.name,
            image: item.icon,
          };
        });
        this.setState({ skuList });
      }
    });
  }

  back() {
    let { history } = this.props;
    history.goBack();
  }

  async inputChange(e, type) {
    let param = cookie.load("token");
    switch (type) {
      case "name":
        this.setState({ name: e.target.value });
        break;
      case "category":
        this.setState({ category: e.target.value });
        break;
      case "image":
        const url = await upload(e, param.token);
        if (url) {
          this.setState({ image: url });
        }
        break;
      default:
        break;
    }
  }

  clickToMove() {
    let { isClick, status } = this.state;
    status = isClick ? 0 : 1;
    this.setState({ isClick: !this.state.isClick, status });
  }

  handleChange = (selected, type) => {
    // 更新已选择的选项
    switch (type) {
      case "country":
        this.setState({ country: selected });
        break;
      case "sku":
        this.setState({ sku: selected });
        break;
      case "category":
        this.setState({ category: selected });
        break;
      default:
        break;
    }
  };

  submit() {
    let { id, sku, country, category, name, status, image } = this.state;
    sku = sku.map((item) => {
      return {
        skuid: item.value,
      };
    });
    this.toggleShow();
    let param = {
      id,
      productname: name,
      menuid: category.value,
      guojiaid: country.value,
      skulist: JSON.stringify(sku),
      status,
      icon: image,
    };
    console.log(param);
    SubmitCompanyAPI.submitCompany(param)
      .then((res) => {
        this.toggleShow();
        var code = res.data.code;
        var msg = res.data.msg;
        if (code === 1) {
          if (id !== 0) {
            window.dispatchEvent(
              new CustomEvent("showAlert", {
                detail: "修改成功",
              })
            );
            this.back();
          } else {
            window.dispatchEvent(
              new CustomEvent("showAlert", {
                detail: "新增成功",
              })
            );
            this.back();
          }
          return;
        }
        window.dispatchEvent(
          new CustomEvent("showAlert", {
            detail: msg,
          })
        );
      })
      .catch((err) => {
        this.toggleShow();
      });
  }

  render() {
    const {
      countryList,
      categoryList,
      skuList,
      isClick,
      category,
      country,
      sku,
      name,
      image,
    } = this.state;

    // 使用 getOptionLabel 自定义选项的渲染
    const skuLabel = (option) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={BASE_URL + option.image}
          alt={option.label}
          style={{ width: "auto", height: 20, marginRight: 10 }}
        />
        <span>{option.label}</span>
      </div>
    );
    const flagLabel = (option) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <FlagIcon
          code={option.label}
          style={{ width: "30px", height: "20px", marginRight: 10 }}
          className="flag"
        />
        <span>{option.label}</span>
      </div>
    );

    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <div className="addCompany-page">
          <CloseIcon
            size={30}
            className="icon close"
            color="#5864ff"
            onClick={this.back}
          />
          <div className="row">
            <div className="title">Company Name:</div>
            <input
              type="text"
              value={name || ""}
              onChange={(e) => this.inputChange(e, "name")}
            />
          </div>
          <div className="row">
            <div className="title">Company Type :</div>
            <Select
              options={categoryList}
              value={category || ""}
              onChange={(selected) => this.handleChange(selected, "category")}
              className="control"
            />
          </div>
          <div className="row">
            <div className="title">Country :</div>
            <Select
              options={countryList}
              value={country || ""}
              onChange={(selected) => this.handleChange(selected, "country")}
              className="control"
              getOptionLabel={flagLabel}
            />
          </div>
          <div className="row">
            <div className="title">Sku:</div>
            <Select
              isMulti="true"
              className="sku-selector"
              value={sku || ""}
              options={skuList}
              onChange={(selected) => this.handleChange(selected, "sku")}
              getOptionLabel={skuLabel}
            />
          </div>
          <div className="row">
            <div className="title">Icon :</div>
            <div className="file">
              <input
                type="file"
                placeholder="image"
                className="file-input"
                onChange={(e) => {
                  this.inputChange(e, "image");
                }}
              />
              {image !== null ? (
                <div className="img">
                  <img src={BASE_URL + image} />
                </div>
              ) : (
                <React.Fragment>
                  <FileIcon size={50} color="#5864ff" />
                  <p className="p">
                    Drop your image here,or browse <br />
                    JPG,PNG and GIF files are allowed
                  </p>
                </React.Fragment>
              )}
            </div>
          </div>
          <div className="row">
            <div className="statuBar">
              <p className="btn-p" onClick={this.clickToMove}>
                ON
              </p>
              <p className="btn-p" onClick={this.clickToMove}>
                OFF
              </p>
              <div className={`btn ${isClick ? "move" : ""}`}></div>
            </div>
          </div>

          <div className="footer">
            <Submit
              size={40}
              color="#5864ff"
              className="icon"
              onClick={this.submit}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddCompany;
