import React from "react";

const CategoryIcon = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#fff",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="11843"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M585.1648 905.1648c-19.8144 0-35.84-16.0256-35.84-35.84v-283.392c0-19.8144 16.0256-35.84 35.84-35.84s35.84 16.0256 35.84 35.84v283.392c0 19.7632-16.0768 35.84-35.84 35.84zM728.9344 905.1648c-19.8144 0-35.84-16.0256-35.84-35.84v-283.392c0-19.8144 16.0256-35.84 35.84-35.84s35.84 16.0256 35.84 35.84v283.392c0 19.7632-16.0768 35.84-35.84 35.84zM875.7248 905.1648c-19.8144 0-35.84-16.0256-35.84-35.84v-283.392c0-19.8144 16.0256-35.84 35.84-35.84s35.84 16.0256 35.84 35.84v283.392c0 19.7632-16.0768 35.84-35.84 35.84z"
      fill={color}
      p-id="11844"
    ></path>
    <path
      d="M234.4448 481.1776h174.7456c35.328 0 64-28.672 64-64v-175.104c0-35.328-28.672-64-64-64H221.5424c-28.2112 0-51.0976 22.8864-51.0976 51.0976v188.0576c0.0512 35.328 28.672 63.9488 64 63.9488zM860.16 178.0736h-187.648c-28.2112 0-51.0976 22.8864-51.0976 51.0976v188.0576c0 35.328 28.672 64 64 64H860.16c35.328 0 64-28.672 64-64v-175.104c0-35.3792-28.672-64.0512-64-64.0512zM410.9824 619.1104H223.3344c-28.2112 0-51.0976 22.8864-51.0976 51.0976v188.0576c0 35.328 28.672 64 64 64h174.7456c35.328 0 64-28.672 64-64v-175.104c0-35.4304-28.672-64.0512-64-64.0512z"
      fill={color}
      p-id="11845"
    ></path>
    <path
      d="M390.3488 105.0624h-224.768c-38.8096 0-70.4 31.5904-70.4 70.4v224.768c0 38.8096 31.5904 70.4 70.4 70.4h224.768c38.8096 0 70.4-31.5904 70.4-70.4v-224.768c0.0512-38.8608-31.5392-70.4-70.4-70.4zM414.72 400.2304c0 13.4144-10.9056 24.32-24.32 24.32h-224.768c-13.4144 0-24.32-10.9056-24.32-24.32v-224.768c0-13.4144 10.9056-24.32 24.32-24.32h224.768c13.4144 0 24.32 10.9056 24.32 24.32v224.768zM390.3488 546.6112h-224.768c-38.8096 0-70.4 31.5904-70.4 70.4v224.768c0 38.8096 31.5904 70.4 70.4 70.4h224.768c38.8096 0 70.4-31.5904 70.4-70.4v-224.768c0.0512-38.8096-31.5392-70.4-70.4-70.4zM414.72 841.7792c0 13.4144-10.9056 24.32-24.32 24.32h-224.768c-13.4144 0-24.32-10.9056-24.32-24.32v-224.768c0-13.4144 10.9056-24.32 24.32-24.32h224.768c13.4144 0 24.32 10.9056 24.32 24.32v224.768zM841.6256 105.0624h-224.768c-38.8096 0-70.4 31.5904-70.4 70.4v224.768c0 38.8096 31.5904 70.4 70.4 70.4h224.768c38.8096 0 70.4-31.5904 70.4-70.4v-224.768c0-38.8608-31.5904-70.4-70.4-70.4z m24.32 295.168c0 13.4144-10.9056 24.32-24.32 24.32h-224.768c-13.4144 0-24.32-10.9056-24.32-24.32v-224.768c0-13.4144 10.9056-24.32 24.32-24.32h224.768c13.4144 0 24.32 10.9056 24.32 24.32v224.768zM571.4944 545.5872a23.04 23.04 0 0 0-23.04 23.04v317.3888c0 12.7488 10.2912 23.04 23.04 23.04s23.04-10.2912 23.04-23.04v-317.3888c0-12.7488-10.2912-23.04-23.04-23.04zM719.0528 545.5872a23.04 23.04 0 0 0-23.04 23.04v317.3888c0 12.7488 10.2912 23.04 23.04 23.04s23.04-10.2912 23.04-23.04v-317.3888a23.04 23.04 0 0 0-23.04-23.04zM866.56 545.5872a23.04 23.04 0 0 0-23.04 23.04v317.3888c0 12.7488 10.2912 23.04 23.04 23.04s23.04-10.2912 23.04-23.04v-317.3888c0-12.7488-10.2912-23.04-23.04-23.04z"
      fill={color}
      p-id="11846"
    ></path>
  </svg>
);


export default CategoryIcon;