import React from "react";

const NewsIcon = ({
    viewBox = '0 0 24 24',
    size = 24,
    color = '#333',
    ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="67794"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M392.682 998.177h516.452V36.507h-819.2v961.67h302.748z m284.939-676.73l35.617-160.278h35.618l35.617 160.278 35.617-160.278h35.618L820.09 374.873h-53.426l-37.398-162.06-33.836 162.06h-53.427l-35.617-213.704h35.617l35.618 160.278zM570.769 161.169v35.617H463.917v53.426h106.852v35.618H463.917v53.426h106.852v35.617h-142.47V161.169h142.47z m-302.748 0l71.235 142.47v-142.47h53.426v213.704h-53.426L266.24 228.842l1.78 146.031h-53.425V161.169h53.426z m195.896 302.748v53.426H214.595v-53.426h249.322zM214.595 659.812v-53.426h249.322v53.426H214.595z m0 89.044h249.322v53.426H214.595v-53.426z m320.556-284.94h284.94v338.366H535.15V463.917z"
      fill={color}
      p-id="67795"
    ></path>
  </svg>
);


export default NewsIcon;