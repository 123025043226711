import ApiEndpointConstants from "../constants/ApiEndpointConstants";
import api from "./API";

export const getSkuList = (page, per_page, userid, url) => {
  return api.post(`${ApiEndpointConstants.GET_SKULIST}`, {
    page,
    per_page,
    userid,
    url,
  });
};

export const getSkuListNoPage = (userid) => {
  return api.post(`${ApiEndpointConstants.GET_SKULIST_NOPAGE}`, { userid });
};
