import React, { Component } from "react";
import "../../style/common/leftBar.scss";
import logo from "../../image/logo.png";
import ChartIcon from "../../icon/ChartIcon";
import UserIcon from "../../icon/UserIcon";
import cookie from "react-cookies";
import NewsIcon from "../../icon/NewsIcon";
import OrderIcon from "../../icon/OrderIcon";
import HomeIcon from "../../icon/HomeIcon";
import Company from "../../icon/Company";
import Charge from "../../icon/Charge";
import Shop from "../../icon/Shop";
import Country from "../../icon/Country";
import Sku from "../../icon/Sku";
import CategoryIcon from "../../icon/CategoryIcon";
import CommentIcon from "../../icon/CommentIcon";
import VIPIcon from "../../icon/VIPIcon";

const iconColor = "rgb(115, 123, 139)";
const iconSize = 20;

class LeftBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usertype: null,
    };
    this.routePage = this.routePage.bind(this);
  }

  componentDidMount() {
    let param = cookie.load("token");
    if (param) {
      console.log(param);
      this.setState({ usertype: param.user.roleType });
    }
  }

  routePage(type) {
    const { history } = this.props;
    console.log(history);
    switch (type) {
      case "home":
        history.push("/Home");
        break;
      case "user":
        history.push("/User");
        break;
      case "company":
        history.push("/Company");
        break;
      case "billing":
        history.push("/Billing");
        break;
      case "order":
        history.push("/Order");
        break;
      case "product":
        history.push("/Product");
        break;
      case "sku":
        history.push("/Sku");
        break;
      case "country":
        history.push("/Country");
        break;
      case "category":
        history.push("/Category");
        break;
      case "news":
        history.push("/News");
        break;
      case "comment":
        history.push("/Comment");
        break;
      case "vip":
        history.push("/VIP");
        break;
      default:
        break;
    }
  }

  render() {
    let { location } = this.props;
    const { usertype } = this.state;
    return (
      <React.Fragment>
        <div className="leftBar">
          <div className="left-box">
            <div className="head-title">
              <div className="img">
                {/* <img src={logo} alt="" /> */}
                Sun
              </div>
              <p>Sun Never Sets</p>
            </div>
            <ul className="tab-list">
              <li
                className={location.pathname === "/Home" ? "active" : ""}
                onClick={() => this.routePage("home")}
              >
                {location.pathname === "/Home" ? (
                  <HomeIcon size={iconSize} className="icon" color="#fff" />
                ) : (
                  <HomeIcon
                    size={iconSize}
                    className="icon"
                    color={iconColor}
                  />
                )}
                <p>Dashboard</p>
              </li>
              {usertype === "admin" && (
                <li
                  className={
                    location.pathname === "/VIP" ||
                    location.pathname.indexOf("/AddVIP") >= 0
                      ? "active"
                      : ""
                  }
                  onClick={() => this.routePage("vip")}
                >
                  {location.pathname === "/VIP" ||
                  location.pathname.indexOf("/AddVIP") >= 0 ? (
                    <VIPIcon size={iconSize} className="icon" color="#fff" />
                  ) : (
                    <VIPIcon
                      size={iconSize}
                      className="icon"
                      color={iconColor}
                    />
                  )}
                  <p>VIP</p>
                </li>
              )}
              {(usertype === "admin" || usertype === "client") && (
                <li
                  className={location.pathname === "/Product" ? "active" : ""}
                  onClick={() => this.routePage("product")}
                >
                  {location.pathname === "/Product" ? (
                    <Shop size={iconSize} className="icon" color="#fff" />
                  ) : (
                    <Shop size={iconSize} className="icon" color={iconColor} />
                  )}
                  <p>Products</p>
                </li>
              )}
              <li
                className={
                  location.pathname === "/User" ||
                  location.pathname.indexOf("/AddUser") >= 0
                    ? "active"
                    : ""
                }
                onClick={() => this.routePage("user")}
              >
                {location.pathname === "/User" ||
                location.pathname.indexOf("/AddUser") >= 0 ? (
                  <UserIcon size={iconSize} className="icon" color="#fff" />
                ) : (
                  <UserIcon
                    size={iconSize}
                    className="icon"
                    color={iconColor}
                  />
                )}
                <p>Accounts</p>
              </li>
              {usertype === "admin" && (
                <React.Fragment>
                  {" "}
                  <li
                    className={
                      location.pathname === "/Category" ||
                      location.pathname.indexOf("/AddCategory") >= 0
                        ? "active"
                        : ""
                    }
                    onClick={() => this.routePage("category")}
                  >
                    {location.pathname === "/Category" ||
                    location.pathname.indexOf("/AddCategory") >= 0 ? (
                      <CategoryIcon
                        size={iconSize}
                        className="icon"
                        color="#fff"
                      />
                    ) : (
                      <CategoryIcon
                        size={iconSize}
                        className="icon"
                        color={iconColor}
                      />
                    )}
                    <p>Categorys</p>
                  </li>
                  <li
                    className={
                      location.pathname === "/Country" ||
                      location.pathname.indexOf("/AddCountry") >= 0
                        ? "active"
                        : ""
                    }
                    onClick={() => this.routePage("country")}
                  >
                    {location.pathname === "/Country" ||
                    location.pathname.indexOf("/AddCountry") >= 0 ? (
                      <Country size={iconSize} className="icon" color="#fff" />
                    ) : (
                      <Country
                        size={iconSize}
                        className="icon"
                        color={iconColor}
                      />
                    )}
                    <p>Countrys</p>
                  </li>
                  <li
                    className={
                      location.pathname === "/Sku" ||
                      location.pathname.indexOf("/AddSku") >= 0
                        ? "active"
                        : ""
                    }
                    onClick={() => this.routePage("sku")}
                  >
                    {location.pathname === "/Sku" ||
                    location.pathname.indexOf("/AddSku") >= 0 ? (
                      <Sku size={iconSize} className="icon" color="#fff" />
                    ) : (
                      <Sku size={iconSize} className="icon" color={iconColor} />
                    )}
                    <p>Skus</p>
                  </li>
                </React.Fragment>
              )}
              <li
                className={
                  location.pathname === "/Company" ||
                  location.pathname.indexOf("/AddCompany") >= 0
                    ? "active"
                    : ""
                }
                onClick={() => this.routePage("company")}
              >
                {location.pathname === "/Company" ||
                location.pathname.indexOf("/AddCompany") >= 0 ? (
                  <Company size={iconSize} className="icon" color="#fff" />
                ) : (
                  <Company size={iconSize} className="icon" color={iconColor} />
                )}
                <p>Companys</p>
              </li>
              <li
                className={
                  location.pathname === "/Billing" ||
                  location.pathname.indexOf("/AddBilling") >= 0
                    ? "active"
                    : ""
                }
                onClick={() => this.routePage("billing")}
              >
                {location.pathname === "/Billing" ||
                location.pathname.indexOf("/AddBilling") >= 0 ? (
                  <Charge size={iconSize} className="icon" color="#fff" />
                ) : (
                  <Charge size={iconSize} className="icon" color={iconColor} />
                )}
                <p>Billings</p>
              </li>
              <li
                className={
                  location.pathname === "/Order" ||
                  location.pathname.indexOf("/AddOrder") >= 0
                    ? "active"
                    : ""
                }
                onClick={() => this.routePage("order")}
              >
                {location.pathname === "/Order" ||
                location.pathname.indexOf("/AddCost") >= 0 ? (
                  <OrderIcon size={iconSize} className="icon" color="#fff" />
                ) : (
                  <OrderIcon
                    size={iconSize}
                    className="icon"
                    color={iconColor}
                  />
                )}
                <p>Order</p>
              </li>
              {usertype === "admin" && (
                <React.Fragment>
                  <li
                    className={
                      location.pathname === "/News" ||
                      location.pathname.indexOf("/AddNews") >= 0
                        ? "active"
                        : ""
                    }
                    onClick={() => this.routePage("news")}
                  >
                    {location.pathname === "/News" ||
                    location.pathname.indexOf("/AddNews") >= 0 ? (
                      <NewsIcon size={iconSize} className="icon" color="#fff" />
                    ) : (
                      <NewsIcon
                        size={iconSize}
                        className="icon"
                        color={iconColor}
                      />
                    )}
                    <p>News</p>
                  </li>
                  <li
                    className={
                      location.pathname === "/Comment" ||
                      location.pathname.indexOf("/AddComment") >= 0
                        ? "active"
                        : ""
                    }
                    onClick={() => this.routePage("comment")}
                  >
                    {location.pathname === "/Comment" ||
                    location.pathname.indexOf("/AddComment") >= 0 ? (
                      <CommentIcon
                        size={iconSize}
                        className="icon"
                        color="#fff"
                      />
                    ) : (
                      <CommentIcon
                        size={iconSize}
                        className="icon"
                        color={iconColor}
                      />
                    )}
                    <p>Comments</p>
                  </li>
                </React.Fragment>
              )}
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default LeftBar;
