import React from "react";

const Pending = ({
  viewBox = "0 0 24 24",
  color = "#8a8a8a",
  size = 24,
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="79621"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M730.688 640H448V293.312c0-22.4 14.912-37.312 37.312-37.312s37.376 14.912 37.376 37.312v272h208c22.4 0 37.312 14.976 37.312 37.376s-14.912 37.312-37.312 37.312zM1024 473.6v-6.4c0-16-16-28.8-35.2-25.6-19.2 0-32 19.2-28.8 35.2v57.6c0 19.2 12.8 32 28.8 35.2h3.2c16 0 32-12.8 32-28.8V473.6z m-67.2 217.6c-16-6.4-35.2 0-41.6 16-9.6 19.2-19.2 35.2-28.8 51.2-9.6 16-6.4 35.2 9.6 44.8 6.4 3.2 12.8 6.4 16 6.4 9.6 0 19.2-6.4 25.6-12.8 12.8-19.2 22.4-38.4 32-57.6 12.8-19.2 3.2-38.4-12.8-48z m-147.2 198.4c-9.6-16-28.8-19.2-44.8-9.6-16 9.6-32 19.2-51.2 28.8-16 6.4-22.4 25.6-12.8 41.6 6.4 12.8 16 19.2 28.8 19.2 6.4 0 9.6 0 16-3.2 19.2-9.6 38.4-22.4 57.6-35.2 12.8-6.4 16-25.6 6.4-41.6z"
      fill={color}
      p-id="79622"
    ></path>
    <path
      d="M969.6 310.4l-70.4-22.4-185.6-60.8 76.8-67.2C710.4 96 614.4 64 512 64 265.6 64 64 265.6 64 512s201.6 448 448 448c19.2 0 32 12.8 32 32s-12.8 32-32 32c-281.6 0-512-230.4-512-512s230.4-512 512-512c118.4 0 233.6 41.6 326.4 118.4L912 51.2l44.8 211.2 12.8 48z"
      fill={color}
      p-id="79623"
    ></path>
  </svg>
);

export default Pending;
