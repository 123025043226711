import React from "react";

const Operational = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "rgb(104, 179, 200)",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="85729"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M337.11 94.748l-182.5 760.89 282.169 75.605 182.5-760.89-282.17-75.605z m22.684-84.65l282.169 75.605a87.637 87.637 0 0 1 61.98 107.335l-182.5 760.89a87.637 87.637 0 0 1-107.335 61.965L131.94 940.288a87.637 87.637 0 0 1-61.98-107.335L252.458 72.064a87.637 87.637 0 0 1 107.335-61.966zM375.38 346.95l84.651 22.685a43.819 43.819 0 0 0 22.684-84.651l-84.65-22.685a43.819 43.819 0 0 0-22.685 84.651zM337.58 488.05l84.65 22.67a43.819 43.819 0 0 0 22.685-84.65l-84.65-22.685a43.819 43.819 0 0 0-22.685 84.665z m406.969-326.614v817.977a29.212 29.212 0 1 0 58.424 0v-817.99a29.212 29.212 0 0 0-58.424 0z m116.864 0v817.977a14.606 14.606 0 1 0 29.212 0v-817.99a14.606 14.606 0 1 0-29.212 0z m58.424 0v817.977a14.606 14.606 0 1 0 29.213 0v-817.99a14.606 14.606 0 1 0-29.213 0z"
      fill={color}
      p-id="85730"
    ></path>
  </svg>
);

export default Operational;
