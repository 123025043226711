import React from "react";

const Wallet = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "rgb(122, 194, 154)",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="62422"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M865.792 296.96h-63.488l11.264-32.768c13.824-41.984-9.216-87.552-50.688-101.888L448.512 58.368c-20.48-6.656-42.496-5.12-61.44 4.608-19.456 9.216-33.28 26.112-39.936 46.592-0.512 2.048-4.608 15.36-5.632 20.48l-2.048-0.512c-20.48-5.12-42.496-2.048-60.928 9.216-17.92 10.752-31.744 29.184-36.352 49.664L215.552 296.96h-47.616c-40.448 0-73.216 32.768-73.216 73.216v414.208c0 77.824 63.488 141.312 141.312 141.312h629.76c34.304 0 61.952-27.648 61.952-61.952V358.912c0.512-34.304-27.648-61.952-61.952-61.952zM393.216 138.24l4.096-12.288c4.608-13.824 19.968-22.016 34.304-16.896l314.368 103.936c6.656 2.048 12.8 7.168 15.872 13.312 3.072 6.144 3.584 13.824 1.536 20.48l-16.896 50.176H721.92c2.56-18.432-1.536-36.864-10.752-52.736-10.752-17.92-28.672-31.744-49.664-36.352L391.68 141.824c0.512-1.024 1.024-2.048 1.536-3.584zM294.4 202.24l0.512-1.024c2.048-7.168 6.144-12.8 12.8-16.896 6.144-3.584 13.312-5.12 20.48-3.072l321.536 78.848c7.168 2.048 12.8 6.144 16.896 12.8 3.584 5.632 5.12 13.312 3.072 20.48-0.512 1.024-0.512 2.048-1.024 3.584H271.36l23.04-94.72z m569.856 659.456H236.032c-42.496 0-77.312-34.816-77.312-77.312V370.176c0-5.12 4.096-8.704 8.704-8.704h696.32v500.224z"
      fill={color}
      p-id="62423"
    ></path>
    <path
      d="M553.472 752.64v34.304c0 4.096-3.584 7.68-7.68 7.68H512c-4.096 0-7.68-3.584-7.68-7.68v-31.744c-19.456-0.512-34.816-3.584-48.64-6.144-3.584-0.512-7.168-3.584-7.168-7.68v-27.136c0-4.096 3.584-7.168 7.168-7.168h0.512c14.848 2.048 52.224 3.584 66.048 3.584 33.28 0 43.52-11.776 43.52-33.792 0-14.848-7.168-22.016-31.744-36.864l-49.152-29.696c-34.816-20.48-44.032-42.496-44.032-68.608 0-34.304 16.896-61.44 63.488-69.12v-31.232c0-4.096 3.584-7.68 7.68-7.68h34.304c4.096 0 7.68 3.584 7.68 7.68V471.04c20.992 1.024 43.008 3.584 52.736 6.144 3.584 0.512 6.656 3.584 6.656 7.168v27.648c0 3.584-2.56 6.656-6.144 6.656h-0.512c-24.576-2.048-49.152-3.584-73.728-3.584-27.648 0-39.424 9.728-39.424 26.624 0 12.288 6.144 19.968 30.72 33.28l45.056 25.088c40.96 23.04 52.224 46.592 52.224 76.288-1.024 32.768-17.92 66.56-68.096 76.288z"
      fill={color}
      p-id="62424"
    ></path>
  </svg>
);

export default Wallet;
