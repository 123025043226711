import React from "react";

const FileIcon = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#9fa4a6",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="34023"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M79.189333 112.426667c10.709333-3.925333 856.576-3.584 868.010667 0.896a105.514667 105.514667 0 0 1 59.861333 61.354666c3.925333 10.709333 3.925333 663.936 0 674.645334a105.514667 105.514667 0 0 1-59.861333 61.354666c-11.434667 4.48-857.301333 4.821333-868.010667 0.896a105.557333 105.557333 0 0 1-62.250666-62.250666c-3.925333-10.709333-3.925333-663.936 0-674.645334a105.557333 105.557333 0 0 1 62.250666-62.250666z m868.565334 473.984l-378.922667 178.261333c-2.602667 1.066667-3.242667 1.450667-5.973333 2.133333a32 32 0 0 1-29.397334-7.594666l-189.909333-175.786667-254.677333 119.808-3.114667 1.28c-2.858667 0.810667-3.541333 1.152-6.485333 1.536a64.384 64.384 0 0 1-2.858667 0.213333c0.256 71.168 0.554667 120.917333 0.981333 121.984 4.224 10.666667 13.056 19.285333 23.808 23.253334 4.138667 1.493333 819.370667 0.981333 823.424-0.725334a41.045333 41.045333 0 0 0 22.314667-23.466666c0.64-1.706667 0.896-112.512 0.810667-240.896z m-0.042667-70.613334c-0.213333-158.976-0.896-319.658667-1.877333-321.834666a41.045333 41.045333 0 0 0-24.917334-22.058667C916.949333 170.666667 105.770667 170.837333 101.205333 172.501333a41.088 41.088 0 0 0-24.149333 24.192c-0.981333 2.645333-1.152 256.682667-0.810667 441.728l259.584-122.112a37.504 37.504 0 0 1 9.045334-2.730666 32.426667 32.426667 0 0 1 21.290666 4.394666c2.389333 1.493333 2.858667 2.005333 5.034667 3.797334l189.909333 175.829333 385.28-181.290667 1.322667-0.512z"
      fill={color}
      p-id="34024"
    ></path>
    <path
      d="M809.173333 234.666667c15.786667 0.597333 31.189333 4.096 45.44 10.965333a112.341333 112.341333 0 0 1 54.357334 57.045333 111.061333 111.061333 0 0 1 6.528 65.834667 112.469333 112.469333 0 0 1-63.317334 79.146667 111.104 111.104 0 0 1-86.784 2.133333 111.957333 111.957333 0 0 1-68.821333-84.010667 112.128 112.128 0 0 1-0.469333-36.608 112.256 112.256 0 0 1 54.698666-79.744 113.109333 113.109333 0 0 1 58.368-14.762666zM805.034667 298.666667a47.402667 47.402667 0 1 0 2.474666 94.805333A47.402667 47.402667 0 0 0 805.034667 298.666667z"
      fill={color}
      p-id="34025"
    ></path>
  </svg>
);

export default FileIcon;
