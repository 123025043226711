import React, { Component } from "react";
import "../style/billing.scss";
import * as GetBillingListAPI from "../apis/GetBillingListAPI";
// import Pagination from "@material-ui/lab/Pagination";
import Loading from "./common/Loading";
import NoContent from "../icon/NoContent";
import cookie from "react-cookies";
import { BASE_URL } from "../constants/ApiEndpointConstants";
import moment from "moment";
import SearchIcon from "../icon/SearchIcon";
import Select from "react-select";
import ChargeIcon from "../icon/ChargeIcon";
import WithdrawIcon from "../icon/WithdrawIcon";
import EditBilling from "../icon/EditBilling";

import Process from "../icon/Processing";
import PaySuucess from "../icon/PaySuccess";
import PayError from "../icon/PayError";
import Pending from "../icon/Pending";
import { Pagination } from "antd";

class Billing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      page: 1,
      per_page: 10,
      count: 0,
      billingList: [],
      userid: 0,
      search: "",
      userType: null,
    };
    this.toggleShow = this.toggleShow.bind(this);
    this.getBillingList = this.getBillingList.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.routeEditBilling = this.routeEditBilling.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.changeSearch = this.changeSearch.bind(this);
    this.clickToSearch = this.clickToSearch.bind(this);
  }

  componentDidMount() {
    let param = cookie.load("token");
    if (param) {
      console.log(param);
      this.setState({ userType: param.user.roleType });
    }
    this.getBillingList(this.state.page, this.state.per_page);
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  getBillingList(page, per_page) {
    this.toggleShow();
    GetBillingListAPI.getBillingList(page, per_page)
      .then((res) => {
        this.toggleShow();
        var data = res.data.data;
        if (data) {
          var total = data.total;
          let count = Math.ceil(total / per_page);
          this.setState({
            billingList: data.list,
            count,
          });
        }
      })
      .catch((err) => {
        this.toggleShow();
      });
  }

  handlePageChange(e, page, pageSize) {
    // 确保 page 和 pageSize 是数字
    const currentPage = parseInt(page, 10);
    const currentPerPage = parseInt(pageSize, 10);

    if (isNaN(currentPage) || isNaN(currentPerPage)) {
      console.error("Invalid page or pageSize");
      return;
    }
    this.setState({ page: currentPage, per_page: currentPerPage }, () => {
      this.getBillingList(currentPage, currentPerPage);
    });
  }

  routeEditBilling(id) {
    const { history } = this.props;
    if (id) {
      history.push(`/EditBilling/${id}`);
      return;
    }
    history.push(`/EditBilling`);
  }

  changeSearch(e) {
    this.setState({ search: e.target.value });
  }

  clickToSearch() {
    this.getCostList(1, this.state.per_page, this.state.search);
  }

  selectChange(e, type) {
    switch (type) {
      case "site":
        this.setState({ site: e }, () => {
          this.getCostList(
            this.state.page,
            this.state.per_page,
            this.state.userid,
            e.label
          );
        });
        break;
      default:
        break;
    }
  }

  render() {
    const { count, page, billingList, userType } = this.state;
    console.log(userType);
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <div className="billing-page">
          <div className="head">
            <div className="left">
              <div className="icon"></div>
              <div className="state">STATUS</div>
              <div className="name">NAME</div>
              <div className="price">PRICE</div>
              <div className="description">DESCRIPTION</div>
              <div className="time">TIME</div>
            </div>
            {userType === "admin" && <div className="tool">OPERATION</div>}
          </div>
          <div className="body">
            {billingList.length > 0 ? (
              <React.Fragment>
                {billingList.map((item, index) => {
                  return (
                    <div className="li" key={index}>
                      <div className="left">
                        <div
                          className={`icon ${
                            item.amount.indexOf("-") >= 0
                              ? "withdraw"
                              : "charge"
                          }`}
                        >
                          {item.type}
                        </div>

                        <div className="state">
                          {" "}
                          {item.status === "pending" && <Pending size={24} />}
                          {item.status === "processing" && (
                            <Process size={24} />
                          )}
                          {item.status === "successed" && (
                            <PaySuucess size={30} color="#196d1f" />
                          )}
                          {item.status === "failed" && (
                            <PayError size={28} color="#d81e06" />
                          )}
                        </div>
                        <div className="name">{item.username}</div>
                        <div className="price">{item.amount}</div>
                        <div className="description">{item.description}</div>
                        <div className="time">
                          {moment(item.createtime * 1000).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </div>
                      </div>
                      {userType === "admin" && (
                        <div className="tool">
                          {item.status === "processing" && (
                            <EditBilling
                              size={25}
                              className="icon"
                              color="#285db1"
                              onClick={() => this.routeEditBilling(item.id)}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </React.Fragment>
            ) : (
              <div className="no-content">
                <NoContent size={200} />
              </div>
            )}
          </div>
        </div>
        <div className="footer">
          <Pagination
            size="small"
            total={this.state.count * this.state.per_page}
            pageSize={this.state.per_page}
            current={this.state.page}
            onChange={(page, pageSize) =>
              this.handlePageChange(null, page, pageSize)
            }
            showQuickJumper
            variant="outlined"
            color="standard"
          />
        </div>
      </React.Fragment>
    );
  }
}

export default Billing;
