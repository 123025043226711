import React, { Component } from "react";
import CloseIcon from "../icon/CloseIcon";
import "../style/addUser.scss";
import Loading from "./common/Loading";
import Submit from "../icon/SubmitIcon";
import * as SubmitUserAPI from "../apis/SubmitUserAPI";
import * as GetUserAPI from "../apis/GetUserAPI";
import { BASE_URL } from "../constants/ApiEndpointConstants";

import FileIcon from "../icon/FileIcon";
import { upload } from "../utils/UploadFile";
import cookie from "react-cookies";
import { img } from "@douyinfe/semi-ui/lib/es/markdownRender/components";
import { Select } from "antd";
import { setAPIAuthorizationToken } from "../apis/API";

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      username: "",
      password: "",
      nickname: "",
      telegramId: "",
      image: null,
      id: 0,
      roleTypeList: [
        {
          value: "admin",
          label: "admin",
        },
        {
          value: "staff",
          label: "staff",
        },
        {
          value: "client",
          label: "client",
        },
      ],
      roleType: null,
      isClick:true,
      state:1,
    };
    this.inputChange = this.inputChange.bind(this);
    this.submit = this.submit.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.back = this.back.bind(this);
    this.clickToMove = this.clickToMove.bind(this);
  }

  componentDidMount() {
    let param = cookie.load("token");
    setAPIAuthorizationToken(param.token);
    let { location } = this.props;
    if (location.pathname.indexOf("/AddUser/") >= 0) {
      let id = location.pathname.replace("/AddUser/", "");
      this.toggleShow();
      GetUserAPI.getUser(id).then((res) => {
        this.toggleShow();
        console.log(res);
        var data = res.data.data;
        if (data) {
          this.setState({
            id: parseInt(id),
            username: data.username,
            password: data.password,
            nickname: data.nickname,
            telegramId: data.telegramId,
            image: data.image,
            roleType: data.roleType,
            state: data.state,
            isClick: data.state > 0 ? true : false,
          });
        }
      });
    }
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  async inputChange(e, type) {
    let param = cookie.load("token");
    switch (type) {
      case "username":
        this.setState({ username: e.target.value });
        break;
      case "password":
        this.setState({ password: e.target.value });
        break;
      case "nickname":
        this.setState({ nickname: e.target.value });
        break;
      case "telegramId":
        this.setState({ telegramId: e.target.value });
        break;
      case "roletype":
        console.log(e);
        this.setState({ roleType: e }, () => {
          console.log(this.state.roleType);
        });
        break;
      case "image":
        const url = await upload(e, param.token);
        if (url) {
          this.setState({ image: url });
        }
        break;
      default:
        break;
    }
  }

  submit() {
    const { id, username, password, nickname, telegramId, roleType, image,state } =
      this.state;
    this.toggleShow();
    let param = {
      id,
      username,
      password,
      nickname,
      telegramId,
      roleType,
      image,
      state
    };
    SubmitUserAPI.submitUser(param).then((res) => {
      this.toggleShow();
      var code = res.data.code;
      var msg = res.data.msg;
      console.log(msg);
      if (code === 1) {
        if (id !== 0) {
          window.dispatchEvent(
            new CustomEvent("showAlert", {
              detail: "Modify user successfully",
            })
          );
          this.back();
        } else {
          window.dispatchEvent(
            new CustomEvent("showAlert", {
              detail: "Add User Successfully",
            })
          );
          this.back();
        }
        return;
      }
      window.dispatchEvent(
        new CustomEvent("showAlert", {
          detail: msg,
        })
      );
    });
  }

  back() {
    let { history } = this.props;
    history.goBack();
  }

  clickToMove() {
    let { isClick, state } = this.state;
    state = isClick ? 0 : 1;
    this.setState({ isClick: !this.state.isClick, state });
  }


  render() {
    const {
      username,
      password,
      nickname,
      telegramId,
      image,
      roleType,
      roleTypeList,
      isClick
    } = this.state;
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <div className="addUser-page">
          <CloseIcon
            size={30}
            className="icon close"
            color="#5864ff"
            onClick={this.back}
          />

          <div className="row">
            <div className="title">Avatar :</div>
            <div className="file">
              <input
                type="file"
                placeholder="image"
                className="file-input"
                onChange={(e) => {
                  this.inputChange(e, "image");
                }}
              />
              {image !== null ? (
                <div className="img">
                  <img src={BASE_URL + image} />
                </div>
              ) : (
                <React.Fragment>
                  <FileIcon size={50} color="#5864ff" />
                  <p className="p">
                    Drop your image here,or browse <br />
                    JPG,PNG and GIF files are allowed
                  </p>
                </React.Fragment>
              )}
            </div>
          </div>
          <div className="row">
            <div className="title">Role Type :</div>
            <Select
              options={roleTypeList}
              className="control"
              value={roleType || {}}
              placeholder="Please select roleType..."
              onChange={(e) => this.inputChange(e, "roletype")}
            />
          </div>
          <div className="row">
            <div className="title">Name :</div>
            <input
              type="text"
              value={username || ""}
              onChange={(e) => this.inputChange(e, "username")}
            />
          </div>

          <div className="row">
            <div className="title">Password :</div>
            <input
              type="text"
              value={password || ""}
              onChange={(e) => this.inputChange(e, "password")}
            />
          </div>
          <div className="row">
            <div className="title">Telegram ID :</div>
            <input
              type="text"
              value={telegramId || ""}
              onChange={(e) => this.inputChange(e, "telegramId")}
            />
          </div>
          <div className="row">
            <div className="title">Nick Name :</div>
            <input
              type="text"
              value={nickname || ""}
              onChange={(e) => this.inputChange(e, "nickname")}
            />
          </div>
          <div className="row">
            <div className="statuBar">
              <p className="btn-p" onClick={this.clickToMove}>
                ON
              </p>
              <p className="btn-p" onClick={this.clickToMove}>
                OFF
              </p>
              <div className={`btn ${isClick ? "move" : ""}`}></div>
            </div>
          </div>
          <div className="footer">
            <Submit
              size={40}
              color="#5864ff"
              className="icon"
              onClick={this.submit}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddUser;
