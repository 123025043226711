import React from "react";

const Country = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#fff",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="8119"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M598.784 938.496c49.152-73.984 112.32-203.584 122.752-388.352h221.824a435.264 435.264 0 0 1-344.576 388.352zM80.704 550.144h224.64c10.24 187.84 70.528 315.136 118.656 388.096-184.704-38.272-326.528-195.456-343.296-388.096z m340.736-463.616c-50.56 73.728-112.576 201.792-117.12 387.072H80.704a435.2 435.2 0 0 1 340.736-387.072zM382.912 473.6c5.376-199.552 85.888-324.864 128.96-377.6 43.648 53.504 125.696 180.352 132.032 377.6H382.912z m128.64 452.672c-41.152-53.568-115.328-176.64-127.36-376.128h258.496c-12.16 194.176-89.088 320.768-131.136 376.128zM943.36 473.6h-220.928c-5.312-183.232-69.248-312.832-120.448-387.328 183.68 39.04 324.608 195.712 341.376 387.328zM512.448 0H512C229.696 0 0 229.632 0 511.872 0 794.368 229.696 1024 512 1024s512-229.632 512-512.128C1024 229.888 794.56 0.256 512.448 0z"
      p-id="8120"
      fill={color}
    ></path>
  </svg>
);

export default Country;
