import React from "react";

const EditBilling = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#333",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="12312"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M438 957c-9.8 0-19.4-4.4-25.8-13l-43.4-58c-9-12-21-19-34-19.8-13.4-0.8-25.8 5.2-36.2 16-62.4 66.6-109.6 61-132.4 52.4-23.2-9-62.4-38.8-62.4-134.2V316c0-191 55-249 235.2-249h324.8c180.2 0 235.2 58 235.2 249v183.2c0 17.6-14.6 32.2-32.2 32.2s-32.2-14.6-32.2-32.2V316c0-155.2-27.2-184.6-170.8-184.6H339.2c-143.6 0-170.8 29.2-170.8 184.6v484.4c0 45.2 11.2 70.2 21.6 74 7.8 3 28.8-1.2 61.6-36.2 23.6-25 54.6-38.2 86.8-36.6 31.8 1.8 62 18.4 82.2 45.6l43.8 58c10.8 14.2 7.8 34.4-6.4 45.2-6.6 4.8-13.6 6.6-20 6.6z"
      p-id="12313"
      fill={color}
    ></path>
    <path
      d="M673 346.6H328.8c-17.6 0-32.2-14.6-32.2-32.2s14.6-32.2 32.2-32.2h344.2c17.6 0 32.2 14.6 32.2 32.2s-14.6 32.2-32.2 32.2zM629.8 518.6h-258c-17.6 0-32.2-14.6-32.2-32.2s14.6-32.2 32.2-32.2h258c17.6 0 32.2 14.6 32.2 32.2s-14.4 32.2-32.2 32.2zM622.2 950.2c-16.4 0-31.8-6-43-17.2-13.4-13.4-19.4-32.6-16.4-53l8.2-58c2.2-15 11.2-33.2 22-43.8l152.2-152.2c20.6-20.6 40.8-31.4 62.8-33.6 26.6-2.6 53.4 8.6 78.2 33.6 25 25 36.2 51.2 33.6 78.2-2.2 21.6-13.4 42.2-33.6 62.8L734 919.2c-10.8 10.8-28.8 19.8-43.8 22l-58 8.2c-3.6 0.2-6.6 0.8-10 0.8z m193-293.8h-1.2c-6 0.4-14.2 6-23.2 15l-152.2 152.2c-1.2 1.2-3.4 5.6-3.4 7.4l-7.8 53.8 53.8-7.8c1.8-0.4 6-2.6 7.4-3.8l152.2-152.2c9-9.4 14.6-17.2 15-23.2 0.8-8.6-7.8-19-15-26.2-7-7.2-16.8-15.2-25.6-15.2z"
      p-id="12314"
      fill={color}
    ></path>
    <path
      d="M841.6 798.2c-3 0-6-0.4-8.6-1.2-56.8-16-102-61-117.8-117.8-4.8-17.2 5.2-34.8 22.4-40 17.2-4.8 34.8 5.2 39.6 22.4 9.8 35.2 37.8 63.2 73.2 73.2 17.2 4.8 27.2 22.8 22.4 40-4.2 14-17 23.4-31.2 23.4z"
      p-id="12315"
      fill={color}
    ></path>
  </svg>
);

export default EditBilling;
