import api from "../apis/API";
import ApiEndpointConstants from "../constants/ApiEndpointConstants";

export const getCategoryList = (page, per_page) => {
  return api.post(`${ApiEndpointConstants.GET_CATEGORYLIST}`, {
    page,
    per_page,
  });
};
export const getCategoryListNoPage = () => {
  return api.post(`${ApiEndpointConstants.GET_CATEGORYLIST_NOPAGE}`);
};
