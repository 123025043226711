import React, { Component } from "react";
import "../style/comment.scss";
// import Pagination from "@material-ui/lab/Pagination";
import AddUserIcon from "../icon/AddUserIcon";
import DeleteIcon from "../icon/DeleteIcon";
import EditIcon from "../icon/EditIcon";
import * as GetCommentListAPI from "../apis/GetCommentListAPI";
import Loading from "./common/Loading";
import NoContent from "../icon/NoContent";
import * as DeleteCommentAPI from "../apis/DeleteCommentAPI";
import { Pagination } from "antd";
import SkuIcon from "../icon/Sku";
import { BASE_URL } from "../constants/ApiEndpointConstants";
import NewsIcon from "../icon/NewsIcon";
import AddCommentIcon from "../icon/AddCommentIcon";
import moment from "moment";
import AlertBox from "./common/AlertBox";

class Comment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      page: 1,
      per_page: 10,
      count: 0,
      commentList: [],
      tieziid: "",
      deleteId: "",
    };
    this.toggleShow = this.toggleShow.bind(this);
    this.getCommentList = this.getCommentList.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.routeAddComment = this.routeAddComment.bind(this);
    this.handlePreviewAlert = this.handlePreviewAlert.bind(this);
    this.confirmHandler = this.confirmHandler.bind(this);
  }

  alertRef = React.createRef();

  componentDidMount() {
    let { location } = this.props;
    if (location.pathname.indexOf("/Comment/") >= 0) {
      let tieziid = location.pathname.replace("/Comment/", "");
      this.setState({ tieziid }, () => {
        this.getCommentList(
          this.state.page,
          this.state.per_page,
          parseInt(tieziid)
        );
      });
      return;
    }
    this.getCommentList(
      this.state.page,
      this.state.per_page,
      this.state.tieziid
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ tieziid: "" }, () => {
        this.getCommentList(this.state.page, this.state.per_page, ""); // 路由变化时重新加载数据
      });
    }
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  getCommentList(page, per_page, id) {
    this.toggleShow();
    GetCommentListAPI.getCommentList(page, per_page, id)
      .then((res) => {
        this.toggleShow();
        var data = res.data.data;
        if (data) {
          var total = data.total;
          console.log(data.list);
          let count = Math.ceil(total / per_page);
          this.setState({
            commentList: data.list,
            count,
          });
        }
      })
      .catch((err) => {
        this.toggleShow();
      });
  }

  handlePageChange(e, page, pageSize) {
    // 确保 page 和 pageSize 是数字
    const currentPage = parseInt(page, 10);
    const currentPerPage = parseInt(pageSize, 10);

    if (isNaN(currentPage) || isNaN(currentPerPage)) {
      console.error("Invalid page or pageSize");
      return;
    }
    this.setState({ page: currentPage, per_page: currentPerPage }, () => {
      this.getNewsList(currentPage, currentPerPage);
    });
  }

  routeAddComment(id) {
    const { history } = this.props;
    if (id) {
      history.push(`/AddComment/${id}`);
      return;
    }
    history.push(`/AddComment`);
  }

  handlePreviewAlert(id) {
    this.handleToggle();
    this.setState({ deleteId: id });
  }

  confirmHandler() {
    this.toggleShow();
    DeleteCommentAPI.deleteComment(this.state.deleteId)
      .then((res) => {
        this.toggleShow();
        var code = res.data.code;
        var msg = res.data.msg;
        if (code === 1) {
          window.dispatchEvent(
            new CustomEvent("showAlert", {
              detail: "Delete comment successfully",
            })
          );
          this.getCommentList(this.state.page, this.state.per_page);
          return;
        }
        window.dispatchEvent(
          new CustomEvent("showAlert", {
            detail: msg,
          })
        );
      })
      .catch((err) => {
        this.toggleShow();
      });
  }

  handleToggle() {
    if (this.alertRef) {
      this.alertRef.current.toggleAlertShow();
    }
  }

  render() {
    const { count, page, commentList } = this.state;
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <AlertBox
          ref={this.alertRef}
          {...this.props}
          confirmHandler={this.confirmHandler}
          toggleAlertShow={this.handleToggle}
        />
        <div className="toolbar">
          <div className="logo" onClick={() => this.routeAddComment("")}>
            <AddCommentIcon size={24} color="#fff" className="icon" />
          </div>
        </div>
        <div className="comment-page">
          <div className="head">
            <div className="left">
              <div className="nickname">POSTER</div>
              <div className="icon">ICON</div>
              <div className="description">DESCRIPTION</div>
              <div className="time">TIME</div>
            </div>
            <div className="tool">OPERATION</div>
          </div>
          <div className="body">
            {commentList.length > 0 ? (
              <React.Fragment>
                {commentList.map((item, index) => {
                  return (
                    <div className="li" key={index}>
                      <div className="left">
                        <div className="nickname">{item.nickname}</div>
                        <div className="icon">
                          <img src={BASE_URL + item.logo} alt="" />
                        </div>
                        <div className="description">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: item.description,
                            }}
                          ></p>
                        </div>
                        <div className="time">
                          {moment(item.createtime * 1000).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </div>
                      </div>
                      <div className="tool">
                        <EditIcon
                          size={25}
                          className="icon"
                          color="#285db1"
                          onClick={() => this.routeAddComment(item.id)}
                        />
                        <DeleteIcon
                          size={25}
                          className="icon"
                          color="#285db1"
                          onClick={() => this.handlePreviewAlert(item.id)}
                        />
                      </div>
                    </div>
                  );
                })}
              </React.Fragment>
            ) : (
              <div className="no-content">
                <NoContent size={200} />
              </div>
            )}
          </div>
        </div>
        <div className="footer">
          <Pagination
            size="small"
            total={this.state.count * this.state.per_page}
            // count={count}
            pageSize={this.state.per_page}
            current={this.state.page}
            onChange={(page, pageSize) =>
              this.handlePageChange(null, page, pageSize)
            }
            showQuickJumper
            variant="outlined"
            color="standard"
          />
        </div>
      </React.Fragment>
    );
  }
}

export default Comment;
