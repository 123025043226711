import api from "../apis/API";
import ApiEndpointConstants from "../constants/ApiEndpointConstants";

export const getVIPlist = (page, per_page) => {
  return api.post(`${ApiEndpointConstants.GET_VIPLIST}`, {
    page,
    per_page,
  });
};
export const getVIPlistNoPage = () => {
  return api.post(`${ApiEndpointConstants.GET_BILLINGLIST_NOPAGE}`);
};
