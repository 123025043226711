import React, { Component, useEffect, useState, useRef, useImperativeHandle } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../style/common/edit.scss";
import Loading from "./Loading";
import ApiEndpointConstants, {
  BASE_URL,
} from "../../constants/ApiEndpointConstants";
import axios from "axios";
import cookie from "react-cookies";

const EditorMix = React.forwardRef((props, ref) => {
  
  const [editorContent, setEditorContent] = useState(props.value || "");
  const [imgArr, setImgArr] = useState([]);
  const [loadingShow, setLoadingShow] = useState(false);

  const selectLocalImage = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    document.body.appendChild(input); // 将 input 添加到 DOM 中
    input.style.display = "none"; // 隐藏 input

    input.click();
    input.onchange = () => {
      const files = input.files;
      if (files.length > 0) {
        uploadImage(files[0]);
      }
      document.body.removeChild(input); // 使用完毕后移除 input
    };
  };

  const [modules] = useState({
    toolbar: {
      container: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image", "video"],
        ["clean"],
      ],
      handlers: {
        image: selectLocalImage, // 这里恢复并设置图片上传处理函数
      },
    },
  });
  const quillRef = useRef(null);


  // 暴露给父组件的方法
  useImperativeHandle(ref, () => ({
    getValue: () => editorContent, // 返回当前的值
  }));


  useEffect(() => {
    // 只有当 props.value 不等于 editorContent 时才更新
    if (props.value !== editorContent) {
      setEditorContent(props.value);
    }
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      const delta = editor.clipboard.convert(props.value); // 将 HTML 转换为 Delta 格式
      editor.setContents(delta); // 使用 setContents 来更新富文本内容
    }
  }, [props.value]); // 依赖于 props.value 和 editorContent

  const handleEditorChange = (content, delta, source, editor) => {
    if (content !== undefined && content !== null) {
      setEditorContent(content);
      // 每次编辑时，调用父组件传递的回调函数
      if (props.onChange) {
        props.onChange(content);
      }
    }
  };

  const uploadImage = async (file, imgIndex) => {
    let param = cookie.load("token");
    var formData = new FormData();
    setLoadingShow((prevLoadingShow) => !prevLoadingShow); //使用函数形式更新状态
    [file].forEach((fileInfo, index) => {
      formData.append("file", fileInfo);
      console.log(formData);
      console.log(fileInfo);
      axios
        .post(BASE_URL + ApiEndpointConstants.UPLOAD, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: param.token,
          },
        })
        .then((res) => {
          console.log("连上了---------");
          setLoadingShow((prevLoadingShow) => !prevLoadingShow);
          let url = res.data.data.fullurl;
          insertToEditor(url);
          console.log(url);
          setImgArr((prevArr) => {
            const newArr = [...prevArr]; // 创建新数组以避免直接修改原数组
            newArr[imgIndex] = url; // 在指定位置插入新的图片链接
            return newArr;
          });
          file = "";
        })
        .catch((err) => {
          setLoadingShow((prevLoadingShow) => !prevLoadingShow);
        });
    });
  };

  const insertToEditor = (url) => {
    if (!url) {
      console.error("URL is undefined");
      return;
    }

    const editor = quillRef.current.getEditor();
    const range = editor.getSelection(true);
    if (range) {
      editor.insertEmbed(range.index, "image", url);
    } else {
      console.warn("No position selected in the editor");
    }
  };

  return (
    <React.Fragment>
      <div className="rich-text-editor">
        <Loading loadingShow={loadingShow} />
        <ReactQuill
          ref={quillRef}
          value={editorContent}
          onChange={handleEditorChange}
          modules={modules}
          placeholder="Please enter your content ..."
        />
      </div>
    </React.Fragment>
  );
});

export default EditorMix;
