import api from "../apis/API";
import ApiEndpointConstants from "../constants/ApiEndpointConstants";

export const getCommentList = (page, per_page, tieziid) => {
  return api.post(`${ApiEndpointConstants.GET_COMMENTLIST}`, {
    page,
    per_page,
    tieziid,
  });
};
