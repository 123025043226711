import React, { Component } from "react";
import "../style/product.scss";
import { FlagIcon } from "react-flag-kit";
import * as GetCategoryListAPI from "../apis/GetCategoryListAPI";
import * as GetLianDongListAPI from "../apis/GetLianDongListAPI";
import { BASE_URL } from "../constants/ApiEndpointConstants";
import * as GetUserAPI from "../apis/GetUserAPI";
import CloseIcon from "../icon/CloseIcon";
import * as GetRelationIdAPI from "../apis/GetRelationIdAPI";
import * as CheckoutAPI from "../apis/CheckoutAPI";
import Loading from "./common/Loading";

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryList: [],
      country: null,
      categoryList: [],
      companyList: [],
      skuList: [],
      company: null,
      sku: null,
      category: null,
      WalletMoney: null,
      description: "",
      checkoutShow: false,
      relation: null,
      loadingShow: false,
    };
    this.select = this.select.bind(this);
    this.getLianDongList = this.getLianDongList.bind(this);
    this.getCategoryList = this.getCategoryList.bind(this);
    this.getWalletMoney = this.getWalletMoney.bind(this);
    this.changeInput = this.changeInput.bind(this);
    this.toggleShowCheckout = this.toggleShowCheckout.bind(this);
    this.submitOrder = this.submitOrder.bind(this);
  }

  componentDidMount() {
    this.getCategoryList();
    this.getWalletMoney();
  }

  getWalletMoney() {
    GetUserAPI.getUser().then((res) => {
      var data = res.data.data;
      console.log(data);
      this.setState({ WalletMoney: data.money });
    });
  }

  getCategoryList() {
    GetCategoryListAPI.getCategoryListNoPage().then((res) => {
      var data = res.data.data.list;
      data.map((item) => {
        item.selected = false;
      });
      this.setState({ categoryList: data });
    });
  }

  select(index, type) {
    let {
      countryList,
      country,
      categoryList,
      category,
      skuList,
      sku,
      companyList,
      company,
    } = this.state;

    switch (type) {
      case "category":
        const updatedCategoryList = categoryList.map((item, itemIndex) => ({
          ...item,
          selected: itemIndex === index,
        }));
        category = updatedCategoryList[index];
        this.setState(
          {
            categoryList: updatedCategoryList,
            category: category.selected ? category : null,
            sku: null,
            country: null,
            company: null,
          },
          () => {
            let param = { menuid: category.id };
            this.getLianDongList(param);
          }
        );
        break;

      case "country":
        const updatedCountryList = countryList.map((item, itemIndex) => ({
          ...item,
          selected: itemIndex === index,
        }));
        country = updatedCountryList[index];
        this.setState(
          {
            countryList: updatedCountryList,
            country: country.selected ? country : null,
            company: null,
            sku: null,
          },
          () => {
            let param = {
              menuid: category?.id || "",
              guojiaid: country?.id || "",
            };
            this.getLianDongList(param);
          }
        );
        break;

      case "sku":
        const updatedSkuList = skuList.map((item, itemIndex) => ({
          ...item,
          selected: itemIndex === index,
        }));
        sku = updatedSkuList[index];
        this.setState(
          {
            skuList: updatedSkuList,
            sku: sku.selected ? sku : null,
            company: null,
          },
          () => {
            let param = {
              menuid: category?.id || "",
              guojiaid: country?.id || "",
              skuid: sku?.id || "",
            };
            this.getLianDongList(param);
          }
        );
        break;

      case "company":
        const updatedCompanyList = companyList.map((item, itemIndex) => ({
          ...item,
          selected: itemIndex === index,
        }));
        company = updatedCompanyList[index];
        console.log(company);
        this.setState(
          {
            companyList: updatedCompanyList,
            company: company.selected ? company : null,
          },
          () => {
            let param = {
              menuid: category?.id || "",
              guojiaid: country?.id || "",
              skuid: sku?.id || "",
              productid: company?.id || "",
            };
            this.getRelationId(param);
          }
        );
        break;

      default:
        break;
    }
  }

  getLianDongList(param) {
    let { countryList, skuList, companyList } = this.state;

    GetLianDongListAPI.getLianDong(param).then((res) => {
      const data = res.data.data;

      const updatedCountryList = data.guojia
        ? data.guojia.map((item) => ({ ...item, selected: false }))
        : countryList;

      const updatedSkuList = data.sku
        ? data.sku.map((item) => ({ ...item, selected: false }))
        : skuList;

      const updatedCompanyList = data.product
        ? data.product.map((item) => ({ ...item, selected: false }))
        : companyList;

      this.setState({
        countryList: updatedCountryList,
        skuList: updatedSkuList,
        companyList: updatedCompanyList,
      });
    });
  }

  getRelationId(param) {
    GetRelationIdAPI.getRelationId(param).then((res) => {
      var data = res.data.data;
      console.log(data);
      this.setState({ relation: data.list[0] });
    });
  }

  changeInput(e) {
    this.setState({ description: e.target.value });
  }

  toggleShowCheckout() {
    this.setState({ checkoutShow: !this.state.checkoutShow });
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  submitOrder() {
    let { company, description, WalletMoney, relation } = this.state;
    console.log(relation);
    let { history } = this.props;
    console.log(WalletMoney);
    if (parseInt(WalletMoney) <= 0) {
      window.dispatchEvent(
        new CustomEvent("showAlert", {
          detail: "No balance in wallet, please recharge first !",
        })
      );
      setTimeout(() => {
        return history.push("/Home");
      }, 1500);
    }
    let param = {
      productskurelationid: relation.id,
      description,
      status: "pending",
    };

    CheckoutAPI.Chekcout(param).then((res) => {
      var data = res.data;
      if ((data.msg = "success")) {
        window.dispatchEvent(
          new CustomEvent("showAlert", {
            detail: "Checkout Successful!",
          })
        );
        this.toggleShowCheckout();
      }
    });
  }

  render() {
    let {
      countryList,
      categoryList,
      skuList,
      companyList,
      category,
      sku,
      company,
      country,
      checkoutShow,
    } = this.state;
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        {/* <AlertBox
          ref={this.alertRef}
          {...this.props}
          confirmHandler={this.confirmHandler}
          toggleAlertShow={this.handleToggle}
        /> */}
        <div className={`checkout-mask ${checkoutShow ? "checkout-show" : ""}`}>
          <div className="checkout-box">
            <CloseIcon
              size={20}
              color="#fff"
              className="close icon"
              onClick={this.toggleShowCheckout}
            />
            <div className="header">CHECKOUT </div>
            <div className="checkout-content">
              <div className="row">
                <div className="title">COMPANY TYPE</div>
                <div className="value">
                  {category && category.menuname}
                  <span>${category && category.menuprice}</span>
                </div>
              </div>
              <div className="row">
                <div className="title">COUNTRY</div>
                <div className="value">
                  <FlagIcon
                    code={country && country.countrycode}
                    style={{ width: "40px", height: "20px" }}
                    className="flag"
                  />
                </div>
              </div>
              <div className="row">
                <div className="title">SKU</div>
                <div className="value">
                  <img src={BASE_URL + (sku && sku.icon)} alt="" />
                  <span>${sku && sku.price}</span>
                </div>
              </div>
              <div className="row">
                <div className="title">COMPANY</div>
                <div className="value">
                  <img src={BASE_URL + (company && company.icon)} alt="" />
                </div>
              </div>
              <div className="row">
                <div className="title">DESCRIPTION</div>
                <div className="value">
                  <textarea
                    placeholder="Please enter your email , phone number here ..."
                    onChange={(e) => this.changeInput(e)}
                  ></textarea>
                </div>
              </div>
              <div className="row">
                <div className="title">TOTAL</div>
                <div className="value">
                  $
                  {category &&
                    sku &&
                    (
                      parseInt(category.menuprice) + parseInt(sku.price)
                    ).toFixed(2)}
                </div>
              </div>
              <div className="btn-row">
                <div className="checkout-btn" onClick={this.submitOrder}>
                  SUBMIT
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="product-page">
          <div className="row">
            <div className="title">Select Company Type</div>
            <div className="content">
              {categoryList &&
                categoryList.map((item, index) => {
                  return (
                    <div
                      className={`li ${item.selected ? "product-active" : ""}`}
                      key={index}
                      onClick={() => this.select(index, "category")}
                    >
                      <div className="li-title">
                        <div className="key"> {item.menuname} Company </div>
                        <div className="value">
                          {item.currency} {item.menuprice}
                        </div>
                        <br />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          {countryList.length > 0 && category !== null && (
            <div className="row">
              <div className="title">Select Country</div>
              <div className="content">
                {countryList &&
                  countryList.map((item, index) => {
                    return (
                      <div
                        className={`li ${
                          item.selected ? "product-active" : ""
                        }`}
                        key={index}
                        onClick={() => this.select(index, "country")}
                      >
                        <div className="li-title">
                          <div className="key">
                            <FlagIcon
                              code={item.countrycode}
                              style={{ width: "50px", height: "25px" }}
                              className="flag"
                            />
                          </div>
                          <div className="value">{item.country}</div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
          {skuList.length > 0 && country !== null && (
            <div className="row">
              <div className="title">Select Sku</div>
              <div className="content">
                {skuList &&
                  skuList.map((item, index) => {
                    return (
                      <div
                        className={`li ${
                          item.selected ? "product-active" : ""
                        }`}
                        key={index}
                        onClick={() => this.select(index, "sku")}
                      >
                        <div className="li-title">
                          <div className="key">
                            <div className="img">
                              <img src={BASE_URL + item.icon} alt="" />
                            </div>
                            <p> {item.name}</p>
                          </div>
                          <div className="value">
                            {item.currency} {item.price}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
          {companyList.length > 0 && sku !== null && (
            <div className="row">
              <div className="title">Select product</div>
              <div className="content">
                {companyList &&
                  companyList.map((item, index) => {
                    return (
                      <div
                        className={`li ${
                          item.selected ? "product-active" : ""
                        }`}
                        key={index}
                        onClick={() => this.select(index, "company")}
                      >
                        <div className="li-title">
                          <div className="key">
                            <div className="img">
                              <img src={BASE_URL + item.icon} alt="" />
                            </div>
                          </div>
                          <div className="value">{item.productname}</div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
          {company !== null && (
            <div className="checkout" onClick={this.toggleShowCheckout}>
              CHECKOUT
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default Product;
