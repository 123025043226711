import React from "react";

const Processing = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#008df0",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="123346"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M512.042667 0c282.752 0 512 229.248 512 512s-229.248 512-512 512-512-229.248-512-512 229.248-512 512-512z m0 256a42.666667 42.666667 0 0 0-42.666667 42.666667v256l0.298667 4.992a42.666667 42.666667 0 0 0 16.768 29.141333l170.666666 128 4.48 2.944a42.666667 42.666667 0 0 0 55.253334-11.477333l2.944-4.48a42.666667 42.666667 0 0 0-11.477334-55.253334L554.666667 533.333333l0.042666-234.666666-0.298666-4.992A42.666667 42.666667 0 0 0 512.042667 256z"
      fill={color}
      p-id="123347"
    ></path>
  </svg>
);

export default Processing;
