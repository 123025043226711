import React from "react";

const CommentIcon = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#696cff",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="17794"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M768 924.672a25.6 25.6 0 0 1-18.090667-7.509333L685.397333 853.333333H433.322667a25.6 25.6 0 0 1-25.6-25.6V636.245333a25.6 25.6 0 0 1 25.6-25.6h308.736v-165.376a25.6 25.6 0 0 1 25.6-25.6h190.976a25.6 25.6 0 0 1 25.6 25.6v382.122667a25.6 25.6 0 0 1-25.6 25.6H849.92L785.066667 917.162667a25.6 25.6 0 0 1-17.066667 7.509333zM458.922667 802.133333h237.056a25.6 25.6 0 0 1 18.090666 7.509334L768 862.890667l53.589333-53.589334a25.6 25.6 0 0 1 17.749334-7.168h93.696V470.869333h-139.776v165.376a25.6 25.6 0 0 1-25.6 25.6H458.922667z"
      fill={color}
      p-id="17795"
    ></path>
    <path
      d="M768 99.328H75.946667A36.181333 36.181333 0 0 0 39.765333 136.533333v499.712a36.181333 36.181333 0 0 0 36.181334 36.352h68.266666a35.669333 35.669333 0 0 1 25.6 10.581334l59.392 59.221333a36.181333 36.181333 0 0 0 51.2 0l59.050667-59.733333a36.352 36.352 0 0 1 25.770667-10.581334H768a36.181333 36.181333 0 0 0 36.181333-36.352V136.533333A36.181333 36.181333 0 0 0 768 99.328zM254.634667 413.184h-23.893334a27.306667 27.306667 0 0 1 0-54.442667h23.893334a27.306667 27.306667 0 0 1 0 54.442667z m167.082666 0h-23.722666a27.306667 27.306667 0 1 1 0-54.442667h23.722666a27.306667 27.306667 0 1 1 0 54.442667z m167.253334 0h-23.893334a27.306667 27.306667 0 0 1 0-54.442667h23.893334a27.306667 27.306667 0 0 1 0 54.442667z"
      fill={color}
      p-id="17796"
    ></path>
  </svg>
);


export default CommentIcon;