import React from "react";

const Shop = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#fff",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="16416"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M170.7008 570.7776c-34.7136-28.3648-56.9344-71.4752-56.9344-119.7056 0-23.04 5.12-45.3632 14.7456-65.8432l35.7376-143.0528c6.3488-25.2928 29.0816-43.1104 55.1936-43.1104h585.1136c26.112 0 48.8448 17.8176 55.1936 43.1104l35.7376 143.0528c9.6256 20.3776 14.7456 42.8032 14.7456 65.8432 0 48.3328-22.2208 91.4432-56.9344 119.7056V896c0 23.552-19.1488 42.7008-42.7008 42.7008H213.2992c-23.552 0-42.7008-19.1488-42.7008-42.7008l0.1024-325.2224z m56.832 29.2864v281.7024h568.9344V600.064c-12.9024 3.4816-26.624 5.4272-40.6528 5.4272-49.5616 0-93.696-23.3472-121.856-59.5968-28.2624 36.2496-72.3968 59.5968-121.856 59.5968s-93.696-23.3472-121.856-59.5968c-28.2624 36.2496-72.3968 59.5968-121.856 59.5968-14.2336 0-27.8528-1.9456-40.8576-5.4272z m0-514.7648h568.9344c15.6672 0 28.4672 12.6976 28.4672 28.4672s-12.6976 28.4672-28.4672 28.4672H227.5328c-15.6672 0-28.4672-12.6976-28.4672-28.4672s12.8-28.4672 28.4672-28.4672z"
      p-id="16417"
      fill={color}
    ></path>
  </svg>
);

export default Shop;
