import React, { Component } from "react";
// import Pagination from "@material-ui/lab/Pagination";
import DeleteIcon from "../icon/DeleteIcon";
import EditIcon from "../icon/EditIcon";
import Loading from "./common/Loading";
import NoContent from "../icon/NoContent";
import "../style/company.scss";
import * as GetCompanyListAPI from "../apis/GetCompanyListAPI";
import * as DeleteCompanyAPI from "../apis/DeleteCompanyAPI";
import AddSite from "../icon/AddSite";
import cookie from "react-cookies";
import Config from "../icon/Config";
import OrderIcon from "../icon/OrderIcon";
import SearchIcon from "../icon/SearchIcon";
import { Pagination } from "antd";
import AddCompany from "../icon/AddCompany";
import { BASE_URL } from "../constants/ApiEndpointConstants";
import { FlagIcon } from "react-flag-kit";
import moment from "moment";

import AlertBox from "./common/AlertBox";

class Company extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      page: 1,
      per_page: 10,
      count: 0,
      companyList: [],
      userid: 0,
      search: "",
      deleteId: "",
    };
    this.toggleShow = this.toggleShow.bind(this);
    this.getCompanyList = this.getCompanyList.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePreviewAlert = this.handlePreviewAlert.bind(this);
    this.routeAddCompany = this.routeAddCompany.bind(this);
    this.changeSearch = this.changeSearch.bind(this);
    this.clickToSearch = this.clickToSearch.bind(this);
    this.confirmHandler = this.confirmHandler.bind(this);
  }

  alertRef = React.createRef();

  componentDidMount() {
    let param = cookie.load("token");
    let { userid } = this.state;
    if (param) {
      console.log(param);
      if (parseInt(param.user.type) !== 2) {
        userid = parseInt(param.user.id);
        this.setState({ userid });
      }
    }
    this.getCompanyList(this.state.page, this.state.per_page, userid);
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  getCompanyList(page, per_page, id, url) {
    this.toggleShow();
    GetCompanyListAPI.getCompanyList(page, per_page, id, url)
      .then((res) => {
        this.toggleShow();
        var data = res.data.data;
        if (data) {
          var total = data.total;
          let count = Math.ceil(total / per_page);
          this.setState({
            companyList: data.list,
            count,
          });
        }
      })
      .catch((err) => {
        this.toggleShow();
      });
  }

  handlePageChange(e, page, pageSize) {
    // 确保 page 和 pageSize 是数字
    const currentPage = parseInt(page, 10);
    const currentPerPage = parseInt(pageSize, 10);

    if (isNaN(currentPage) || isNaN(currentPerPage)) {
      console.error("Invalid page or pageSize");
      return;
    }
    this.setState({ page: currentPage, per_page: currentPerPage }, () => {
      this.getCompanyList(
        currentPage,
        currentPerPage,
        this.state.userid,
        this.state.search
      );
    });
  }

  routeAddCompany(id) {
    const { history } = this.props;
    if (id) {
      history.push(`/AddCompany/${id}`);
      return;
    }
    history.push(`/AddCompany`);
  }

  changeSearch(e) {
    this.setState({ search: e.target.value });
  }

  clickToSearch() {
    this.getSiteList(
      1,
      this.state.per_page,
      this.state.userid,
      this.state.search
    );
  }

  handlePreviewAlert(id) {
    this.handleToggle();
    this.setState({ deleteId: id });
  }

  handleToggle() {
    if (this.alertRef) {
      this.alertRef.current.toggleAlertShow();
    }
  }
  confirmHandler() {
    this.toggleShow();
    DeleteCompanyAPI.deleteCompany(this.state.deleteId)
      .then((res) => {
        this.toggleShow();
        var code = res.data.code;
        var msg = res.data.msg;
        if (code === 1) {
          window.dispatchEvent(
            new CustomEvent("showAlert", {
              detail: "Delete Company Successfully",
            })
          );
          this.getCompanyList(this.state.page, this.state.per_page);
          return;
        }
        window.dispatchEvent(
          new CustomEvent("showAlert", {
            detail: msg,
          })
        );
      })
      .catch((err) => {
        this.toggleShow();
      });
  }

  render() {
    const { count, page, companyList, userid, search } = this.state;
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />{" "}
        <AlertBox
          ref={this.alertRef}
          {...this.props}
          confirmHandler={this.confirmHandler}
          toggleAlertShow={this.handleToggle}
        />
        <div className="toolbar">
          {/* <div className="search">
            <input
              type={search || ""}
              placeholder="Search"
              onChange={(e) => this.changeSearch(e)}
              onKeyDown={(e) => e.key === "Enter" && this.clickToSearch()}
            />
          </div> */}
          <div className="logo" onClick={() => this.routeAddCompany("")}>
            <AddCompany size={25} color="#fff" className="icon" />
          </div>
        </div>
        <div className="company-page">
          <div className="head">
            <div className="left">
              <div className="name">NAME</div>
              <div className="icon">ICON</div>
              <div className="country">COUNTRY</div>
              <div className="category">CATEGORY</div>
              <div className="sku">SKU</div>
              <div className="creator">CREATOR</div>
              <div className="state">STATE</div>
              <div className="time">TIME</div>
            </div>
            <div className="tool">OPERATION</div>
          </div>
          <div className="body">
            {companyList.length > 0 ? (
              <React.Fragment>
                {companyList.map((item, index) => {
                  return (
                    <div className="li" key={index}>
                      <div className="left">
                        <div className={`type ${item.menuname.toLowerCase()}`}>
                          {item.menuname}
                        </div>
                        <div className="name">{item.productname}</div>
                        <div className="icon">
                          <img src={BASE_URL + item.icon} alt="" />
                        </div>
                        <div className="country">
                          <FlagIcon
                            code={item.countrycode}
                            style={{ width: "30px", height: "20px" }}
                            className="flag"
                          />
                        </div>
                        <div className="category">{item.menuname}</div>
                        <div className="sku">
                          {item.skulist &&
                            item.skulist.map((sku, skuIndex) => {
                              return (
                                <img
                                  src={BASE_URL + sku.icon}
                                  alt=""
                                  key={skuIndex}
                                />
                              );
                            })}
                        </div>
                        <div className="creator">{item.chuanjianname}</div>
                        <div
                          className={`state ${item.status > 0 ? "on" : "off"}`}
                        >
                          {item.status > 0 ? "ON" : "OFF"}
                        </div>
                        <div className="time">
                          {moment(item.createtime * 1000).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </div>
                      </div>
                      <div className="tool">
                        <React.Fragment>
                          <EditIcon
                            size={30}
                            className="icon"
                            color="#285db1"
                            onClick={() => this.routeAddCompany(item.id)}
                          />
                          <DeleteIcon
                            size={25}
                            className="icon"
                            color="#285db1"
                            onClick={() => this.handlePreviewAlert(item.id)}
                          />
                        </React.Fragment>
                      </div>
                    </div>
                  );
                })}
              </React.Fragment>
            ) : (
              <div className="no-content">
                <NoContent size={200} />
              </div>
            )}
          </div>
        </div>
        <div className="footer">
          <Pagination
            size="small"
            total={this.state.count * this.state.per_page}
            // count={count}
            pageSize={this.state.per_page}
            current={this.state.page}
            onChange={(page, pageSize) =>
              this.handlePageChange(null, page, pageSize)
            }
            showQuickJumper
            variant="outlined"
            color="standard"
          />
        </div>
      </React.Fragment>
    );
  }
}

export default Company;
