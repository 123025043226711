import React from "react";

const Sku = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#fff",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="10516"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M861.44 266.752l-310.8864-179.5072a76.288 76.288 0 0 0-75.8784 0L163.7376 266.752a76.288 76.288 0 0 0-37.9392 65.7408v359.0144c0 26.9824 14.5408 52.224 37.9392 65.7408l310.8864 179.5072a76.3904 76.3904 0 0 0 75.8784-0.0512l310.8864-179.5072c23.3984-13.5168 37.9392-38.7072 37.9392-65.7408V332.4928a76.0832 76.0832 0 0 0-37.888-65.7408z m-63.7952 112.3328l-259.3792 146.5856v302.2848a25.6 25.6 0 0 1-51.2 0v-302.8992L228.7104 379.0848a25.6 25.6 0 0 1 25.2416-44.5952l259.2768 146.4832 259.2256-146.4832a25.7024 25.7024 0 0 1 34.9184 9.6768 25.7024 25.7024 0 0 1-9.728 34.9184z"
      fill={color}
      p-id="10517"
    ></path>
  </svg>
);


export default Sku;