export const BASE_URL =
  window.location.host.indexOf("localhost") < 0
    ? window.location.protocol + "//" + window.location.host
    : "https://work.rateose.store";

export default {
  GET_CODE: "/api/Yonghu/telms",
  LOGIN: "/api/Yonghu/login",
  UPLOAD: "/api/common/upload",
  USERLIST: "/api/Employeetable/getEmployeetableList",
  SITELIST: "/api/Urlrelation/getUrlrelationList",
  COSTLIST: "/api/Consumptionschedule/getconsumptionScheduleList",
  ADDUSER: "/api/Employeetable/setEmployeetable",
  USER: "/api/Employeetable/getEmployeetable",
  DELETE_USER: "/api/Employeetable/delEmployeetable",
  USERLIST_NOPAGE: "/api/Employeetable/getEmployeetableListN",
  STIELIST_NOPAGE: "/api/Urlrelation/getUrlrelationListN",
  ADDSITE: "/api/Urlrelation/setUrlrelation",
  SITE: "/api/Urlrelation/getUrlrelation",
  DELETE_SITE: "/api/Urlrelation/delUrlrelation",
  ADDCOST: "/api/Consumptionschedule/setconsumptionSchedule",
  COST: "/api/Consumptionschedule/getconsumptionSchedule",
  DELETE_COST: "/api/Consumptionschedule/delconsumptionSchedule",
  GET_CHART: "/api/trend/baobiao",
  BANGDAN: "/api/trend/baobiaoii",
  CONFIG: "/api/Urlrelation/getUrlrelationUrl",
  ORDER: "/api/Urlorder/getOrderList",
  PIXEL: "/api/Events/groupevent",
  AD: "/api/Events/groupad",
  EDIT_CONFIG: "/api/Urlrelation/setUrlrelationII",
  // user
  ADD_USER: "/api/Yonghu/setYonghu",
  GET_USER: "/api/Yonghu/getYonghu",
  DELETE_USER: "/api/Yonghu/delYonghu",
  GET_USERLIST: "/api/Yonghu/getYonghuList",
  GET_USERLIST_NOPAGE: "/api/Yonghu/getYonghuListN",
  // country
  ADD_COUNTRY: "/api/Guojia/setGuojia",
  GET_COUNTRY: "/api/Guojia/getGuojia",
  DELETE_COUNTRY: "/api/Guojia/delGuojia",
  GET_COUNTRYLIST: "/api/Guojia/getGuojiaList",
  GET_COUNTRYLIST_NOPAGE: "/api/Guojia/getGuojiaListN",
  // sku
  ADD_SKU: "/api/Sku/setSku",
  GET_SKU: "/api/Sku/getSku",
  DELETE_SKU: "/api/Sku/delSku",
  GET_SKULIST: "/api/Sku/getSkuList",
  GET_SKULIST_NOPAGE: "/api/Sku/getSkuListN",
  // product
  ADD_PRODUCT: "/api/Product/setProduct",
  GET_PRODUCT: "/api/Product/getProduct",
  DELETE_PRODUCT: "/api/Product/delProduct",
  GET_PRODUCTLIST: "/api/Product/getProductList",
  GET_PRODUCTLIST_NOPAGE: "/api/Product/getProductListN",
  // category
  ADD_CATEGORY: "/api/Menu/setMenu",
  GET_CATEGORY: "/api/Menu/getMenu",
  DELETE_CATEGORY: "/api/Menu/delMenu",
  GET_CATEGORYLIST: "/api/Menu/getMenuList",
  GET_CATEGORYLIST_NOPAGE: "/api/Menu/getMenuListN",
  // billing
  GET_BILLING: "/api/Zhangdan/getZhangdan",
  GET_BILLINGLIST: "/api/Zhangdan/getZhangdanList",
  GET_BILLINGLIST_NOPAGE: "/api/Zhangdan/getZhangdanListN",
  RECHARGE: "/api/Zhangdan/chongzhi",
  EDIT_BILLING: "/api/Zhangdan/zhangdanshenhe",
  // order
  EDIT_ORDER: "/api/Dingdan/setDingdan",
  GET_ORDER: "/api/Dingdan/getDingdan",
  GET_ORDERLIST: "/api/Dingdan/getDingdanList",
  GET_ORDERLIST_NOPAGE: "/api/Dingdan/getDingdanListN",
  // new
  ADD_STORY: "/api/Tiezi/setTiezi",
  GET_STORY: "/api/Tiezi/getTiezi",
  DELETE_STORY: "/api/Tiezi/delTiezi",
  GET_STORYLIST: "/api/Tiezi/getTiezilist",
  GET_STORYLIST_NOPAGE: "/api/Tiezi/getTiezilistN",
  // new comment
  ADD_COMMENT: "/api/Pinglun/setPinglun",
  GET_COMMENT: "/api/Pinglun/getPinglun",
  DELETE_COMMENT: "/api/Pinglun/delPinglun",
  GET_COMMENTLIST: "/api/Pinglun/getPinglunlist",
  GET_STORY_COMMNETLIST: "/api/Pinglun/getPinglunlisttiezi",
  // SHOP
  GET_SHOP: "/api/Relation/getLiandong",
  // invite code
  GET_INVITECODE: "/api/Yonghu/getYaoqingma",
  // LIAN DONG
  GET_LIANDONG: "/api/Relation/getLiandong",
  // 获取relationID
  GET_RELATIONID: "/api/Relation/getProductskurelationList",
  // chart
  GET_DASHBOARD: "/api/Trend/baobiao",
  // vip
  ADD_VIP: "/api/Huiyuan/setHuiyuan",
  GET_VIP: "/api/Huiyuan/getHuiyuan",
  DELETE_VIP: "/api/Huiyuan/delHuiyuan",
  GET_VIPLIST: "/api/Huiyuan/getHuiyuanList",
  GET_VIPLIST_NOPAGE: "/api/Huiyuan/getHuiyuanListN",
};
