import React from "react";

const NotifyIcon = ({
  size = 24,
  viewBox = "0 0 24 24",
  color = "#204f6e",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="4096"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M24.380952 292.571429h975.238096v731.428571H24.380952V292.571429z m162.523429 162.523428v68.266667h650.191238v-68.266667H186.904381z m0 162.572191v68.266666h650.191238v-68.266666H186.904381z m0 162.523428v68.266667h650.191238V780.190476H186.904381z"
      fill={color}
      p-id="4097"
    ></path>
    <path
      d="M512 48.761905l390.095238 292.571428H121.904762l390.095238-292.571428z m-3.413333 87.868952L310.418286 292.571429h398.189714l-199.92381-155.940572z"
      fill={color}
      p-id="4098"
    ></path>
    <path
      d="M509.318095 105.130667m-73.142857 0a73.142857 73.142857 0 1 0 146.285714 0 73.142857 73.142857 0 1 0-146.285714 0Z"
      fill={color}
      p-id="4099"
    ></path>
  </svg>
);

export default NotifyIcon;
