import React from "react";

const VIPIcon = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#dbdbdb",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="53497"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M796.387556 853.333333H227.555556a56.888889 56.888889 0 1 0 0 113.777778h568.832a56.888889 56.888889 0 1 0 0-113.777778zM1021.383111 303.672889c-9.784889-32.483556-40.163556-51.825778-73.045333-45.909333-31.630222 5.404444-54.044444 34.929778-52.337778 68.437333 0.796444 17.806222 8.874667 32.369778 21.447111 45.169778-3.356444 2.104889-5.973333 3.925333-8.874667 5.745777-60.757333 38.115556-121.457778 76.458667-182.215111 114.403556-15.985778 9.898667-21.845333 8.362667-30.890666-8.704a137610.410667 137610.410667 0 0 0-159.857778-298.496c42.723556-29.866667 52.565333-69.575111 25.543111-103.480889a62.919111 62.919111 0 0 0-93.980444-5.461333c-13.482667 13.653333-20.024889 30.378667-19.228445 49.834666 1.365333 27.932444 16.611556 46.648889 40.220445 58.936889-54.499556 102.115556-108.657778 203.377778-162.702223 304.355556-5.518222 10.183111-13.425778 12.003556-23.608889 5.802667a3454.862222 3454.862222 0 0 1-53.646222-33.507556c-46.990222-29.582222-94.151111-59.107556-141.710222-88.974222 2.161778-2.787556 4.096-5.063111 6.087111-7.224889 17.066667-20.707556 20.252444-48.526222 8.590222-72.078222-11.434667-23.153778-35.896889-37.888-60.472889-36.067556-26.908444 1.820444-49.038222 19.342222-57.457777 45.112889-1.024 3.242667-2.161778 6.257778-3.242667 9.500444 0 7.566222 0 14.961778 0.398222 23.096889 2.503111 6.428444 4.380444 12.970667 7.566222 18.887111 12.8 24.007111 33.336889 34.986667 60.416 34.986667 7.907556 27.648 15.758222 55.011556 23.495112 82.432 29.070222 101.546667 57.799111 203.207111 86.584888 304.526222 4.664889 16.611556 9.955556 20.707556 27.079112 20.707556h613.831111c16.042667 0 21.617778-4.380444 26.225777-20.707556 35.612444-124.928 71.111111-250.197333 106.609778-375.239111 1.194667-3.925333 2.446222-7.68 3.811556-11.605333 36.352-0.227556 57.571556-19.968 67.982222-53.873778v-22.869333c-0.796444-2.503111-1.934222-5.233778-2.616889-7.736889z"
      fill={color}
      p-id="53498"
    ></path>
  </svg>
);

export default VIPIcon;
